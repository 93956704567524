import { grey } from "@mui/material/colors";

const styles = {
  button: {
    textTransform: "none",
    color: "black",
    border: `1px solid ${grey[500]}`,
  },
  textField: {
    mb: 2,
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  delete: {
    color: "secondary.main",
    fontSize: 12,
  },
  nicheText: {
    color: "black",
    fontSize: 18,
    fontWeight: 600,
  },
};

export default styles;
