import {
  Box,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OnboardAction } from "schemas/dashboard";

import ConnectGmailDialog from "components/ConnectGmailDialog";
import { BENTO_VA_ONBOARDING_CALENDLY_LINK } from "constants/influencer";
import { getSource, trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import styles from "./styles";

interface Props {
  paidThroughSettings: boolean;
}
const VASteps = ({ paidThroughSettings }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);
  const { trackUserHasOnboarded, trackVaStep } = useTracking();
  const source = getSource();

  const { currentOrg } = useContext(OrganizationUserContext);
  const { emailHealth, fetchIntegrationHealth, userIntegration, scopes } =
    useContext(UserIntegrationsContext);
  const { userIntegrations } = useContext(OrganizationUserContext);
  const [activeStep, setActiveStep] = useState(0);
  const [userIntegrationDialogState, setUserIntegrationDialogState] =
    useState(false);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    let locationSearch = location.search;
    if (location.search?.includes("checkout=success")) {
      locationSearch = locationSearch.replace("checkout=success", "");
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back", paidThroughSettings)}${locationSearch}`,
    );
  };

  const handleNext = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackVaStep(location.pathname, source);
    trackUserHasOnboarded(OnboardAction.VA_FLOW_COMPLETED);
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next")}${location.search}`,
    );
  };

  const steps = [
    {
      label: "Connect your Gmail Account and provide Read + Write Access",
      description: `Providing access to your Gmail account allows Bento's virtual assistant to send emails on your behalf, automate follow-ups, improve your response rates and send you reminders.`,
    },
    {
      label: "Book a 1:1 Onboarding Call with Bento's CEO",
      description:
        "During this onboarding call, we will create a personalized pitch together and answer any questions you have.",
    },
  ];

  const connectToGmail = () => {
    trackEvent("VA Onboarding Flow - Connect to Gmail Button Clicked");
    setUserIntegrationDialogState(true);
  };

  const bookCall = () => {
    trackEvent("VA Onboarding Flow - Book Call Button Clicked");
  };

  useEffect(() => {
    if (emailHealth) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth, userIntegration?.partnershipsSearchedAt]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const onEmailConnected = async () => {
    setUserIntegrationDialogState(false);
    setActiveStep(1);
  };

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Welcome to Bento's Income Guaranteed Program 🎉
      </Typography>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        You have {steps?.length} action items to get started
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography sx={styles.description}>
                {step.description}
              </Typography>

              {activeStep === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={connectToGmail}
                  size="small"
                  disableElevation
                  sx={{ mr: 1, mt: 1 }}
                >
                  Connect
                </Button>
              )}
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={bookCall}
                  size="small"
                  disableElevation
                  sx={{ mr: 1, mt: 1 }}
                  href={BENTO_VA_ONBOARDING_CALENDLY_LINK}
                  target="_blank"
                >
                  Book Call
                </Button>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button
            disableElevation
            variant="contained"
            disabled={!emailHealth}
            onClick={handleNext}
          >
            I've Booked
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-solid fa-arrow-right"
            />
          </Button>
        </Grid>
      </Grid>

      {/* Create a custom gmail connect in order to perform action automatically
      after connecting */}
      <ConnectGmailDialog
        openIntegrationDialog={userIntegrationDialogState}
        setOpenIntegrationDialog={(newOpen: boolean) =>
          setUserIntegrationDialogState(newOpen)
        }
        userIntegrations={userIntegrations}
        userIntegration={userIntegration}
        scopes={scopes}
        emailHealth={emailHealth}
        onSuccessAction={onEmailConnected}
        title="Connect your Gmail account"
      />
    </>
  );
};

export default VASteps;
