import {
  Avatar,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { Dispatch, SetStateAction, useContext } from "react";
import { BentoBrand, EmailSequence } from "schemas/dashboard";

import { CategoryMap } from "components/BulkSendDialog/schema";
import { getSourceLabel } from "utils/influencer";
import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  bentoBrands: BentoBrand[];
  setCategories: Dispatch<SetStateAction<CategoryMap>>;
  category: string;
  removeFromBulkBrands: (bentoBrand: BentoBrand) => void;
}

const EditBrandList = ({
  bentoBrands,
  setCategories,
  category,
  removeFromBulkBrands,
}: Props) => {
  const { selectedCollectionId } = useContext(SavedBrandCollectionsContext);
  const { handleRemoveDebounced } = useContext(SavedBrandContext);
  const handleDelete = (bentoBrand: BentoBrand) => {
    setCategories((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy[category].emailSequences.findIndex(
        (es: EmailSequence) => es.bentoBrand.id === bentoBrand.id,
      );
      if (index > -1) {
        copy[category].emailSequences.splice(index, 1);
      }

      if (copy[category].emailSequences.length === 0) {
        delete copy[category];
      }
      return copy;
    });
    // Remove from the selected bulk schedule on the sidebar
    removeFromBulkBrands(bentoBrand);
    // Archive on BE so it will not be add to automation again
    if (bentoBrand?.isAdmin || bentoBrand?.createdByAutomation) {
      handleRemoveDebounced(bentoBrand, selectedCollectionId || 0);
    }
  };

  const goToInstagram = (bentoBrand: BentoBrand) => {
    trackEvent("Instagram Link Viewed", {
      "Instagram Username": bentoBrand.instagramUsername,
    });
    window.open(
      `https://instagram.com/${bentoBrand.instagramUsername}`,
      "_blank",
    );
  };

  const renderRow = (bentoBrand: BentoBrand) => {
    return (
      <Box sx={styles.brandContainer} key={bentoBrand.id}>
        <IconButton
          onClick={() => handleDelete(bentoBrand)}
          sx={styles.removeButton}
        >
          <Box component="i" className="fa-regular fa-circle-xmark" />
        </IconButton>

        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>{bentoBrand.brandName}</Box>

          {bentoBrand.instagramUsername && (
            <Link
              target="_blank"
              onClick={() => goToInstagram(bentoBrand)}
              sx={{ fontSize: 12 }}
            >
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          <Grid item xs={12}>
            <FormHelperText sx={{ color: "secondary.main" }}>
              {getSourceLabel(bentoBrand)}
            </FormHelperText>
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Typography>
        You can remove a brand from the list to not include it in bulk send.
      </Typography>
      {bentoBrands?.map((bentoBrand) => renderRow(bentoBrand))}
    </>
  );
};

export default EditBrandList;
