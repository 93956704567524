import { useAuth } from "@clerk/clerk-react";
import { Grid, Slide, useTheme } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import {
  ENTER_DELAY,
  EXIT_DELAY,
  SlideAnimationContext,
  SlideAnimationProvider,
} from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SearchParams, VAStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Alert from "components/Alert";
import Loading from "components/Loading";
import { PAGE_VISITED } from "constants/trackingProps";
import { fetcherAuth } from "utils/api";
import {
  CURRENT_VA_STEP,
  HIDE_VA_ONBOARDING_VIDEO,
  ON_BEHALF_OF_TOKEN,
} from "utils/localStorage";
import { getSource, identifyUser, trackEvent } from "utils/tracking";
import { useAlert } from "utils/useAlert";

import AboutForm from "../Onboarding/AboutForm";
import { getUserType } from "../Onboarding/AboutForm/helpers";
import CategoryForm from "../Onboarding/CategoryForm";
import RecommendationForm from "../Onboarding/RecommendationForm";
import { vaOnboardingNavigate } from "../Onboarding/helper";
import VAIntroduction from "../VAOnboarding/VAIntroduction";
import VAPaidPortal from "../VAOnboarding/VAPaidPortal";
import VAQuestions from "../VAOnboarding/VAQuestions";
import VASteps from "../VAOnboarding/VASteps";
import IncomeForm from "./IncomeForm";
import { getIncomeLevel } from "./IncomeForm/helpers";
import VANiche from "./VANiche";
import VAVideo from "./VaVideo";
import styles from "./styles";

const OnboardingVAInfluencer = () => {
  const {
    currentOrg,
    currentUser,
    setCurrentUser,
    setCurrentOrg,
    setUserNotifications,
    setUserIntegrations,
    setEmailSettings,
    setHideCityDialog,
    setProfile,
  } = useContext(OrganizationUserContext);
  const theme = useTheme();
  const { setSubscription } = useContext(SubscriptionContext);
  const [searchParams] = useSearchParams();

  const { slideOutUp, slideInUp, slideOutDown, slideInDown, slideProps } =
    useContext(SlideAnimationContext);

  const referralCode = searchParams.get(SearchParams.REFERRAL_CODE);

  const [message, severity, setAlert, closeAlert] = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, signOut } = useAuth();

  const [fetchUserLoading, setFetchUserLoading] = useState(true);
  const source = getSource();

  const { isVaPlan, subscription } = useContext(SubscriptionContext);

  const [onboardingForm, setOnboardingForm] = useState<Map>({
    name: currentUser?.name || "",
    location: "",
    brands: [],
    wishlistBrands: [],
    categories: [],
    isUgcCreator: null,
    isInfluencer: null,
    incomeLevel: "",
    pastBrandValidation: null,
    primarySocialMediaPlatform: "instagram",

    // These fields are auto-filled when user sign up
    email: "",
    organizations: [],
    organizationId: "",
    organizationName: "",
    type: "influencer",
  });

  const { emailHealth, setEmailHealth, setScopes, setUserIntegration } =
    useContext(UserIntegrationsContext);

  const fetchUserInfo = async () => {
    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);
      if (res.organizationProfile) {
        const copy = { ...res.organizationProfile };
        setProfile(copy);
      }
      const hasOnboarded = res.organization.vaStatus === VAStatus.approved;
      setFetchUserLoading(false);
      const isInVaOnboardingFlow =
        res.organization.vaStatus === VAStatus.pending ||
        (res.organization.signedUpForVa &&
          res.organization.vaStatus !== VAStatus.not_interested &&
          res.organization.vaStatus !== VAStatus.disqualified);

      // Redirect to dashboard if they already onboard or not a VA user.
      if (!isInVaOnboardingFlow || hasOnboarded) {
        navigate(`/${routes.brands}/all${location.search}`);
        return;
      }

      setHideCityDialog(true);
      if (res.organizationProfile) {
        setOnboardingForm((prev) => ({
          ...prev,
          ...res.organizationProfile,
          name: res.organizationUser.name,
          userType: getUserType(res.organizationProfile),
          incomeLevel: getIncomeLevel(res.organizationProfile),
        }));
      } else {
        setOnboardingForm((prev) => ({
          ...prev,
          name: res.organizationUser.name,
        }));
      }

      slideInUp();

      if (!res?.organizationProfile) {
        navigate(`/${routes.aboutVaOnboarding}${location.search}`);
        return;
      }

      if (
        !new RegExp(`${routes.onboardingVaInfluencer}/?$`).test(
          location.pathname,
        )
      ) {
        return;
      }

      const hasCategories =
        res.organizationProfile.categories.length > 0 ||
        res.organizationProfile.selectedCategories.length > 0;
      if (!hasCategories) {
        navigate(`/${routes.categoryVaOnboarding}${location.search}`);
        return;
      }

      if (
        ![VAStatus.not_interested, VAStatus.disqualified]?.includes(
          res.organization.vaStatus,
        )
      ) {
        const currentVaStep = localStorage.getItem(
          `${CURRENT_VA_STEP}-${res.organization.id}`,
        );
        const hideVaOnboardingVideo = localStorage.getItem(
          `${HIDE_VA_ONBOARDING_VIDEO}-${res.organization.id}`,
        );
        const isSubscribedEmail = window.location.href?.includes(
          `s=email&ref=va_subscribed`,
        );
        let grantedEmailAccess = emailHealth;
        const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));
        if (!grantedEmailAccess) {
          const healthCheckRes = await fetcherAuth(
            getToken,
            `/api/organization/${res.organization.id}/user-integrations/health-check`,
          );
          grantedEmailAccess = healthCheckRes.emailHealth;
          setEmailHealth(healthCheckRes.emailHealth);
          setScopes(healthCheckRes.scopes);
          setUserIntegration(healthCheckRes.userIntegration);
        }

        if (
          !res.organizationProfile?.wishlistBrands ||
          res.organizationProfile?.wishlistBrands?.length === 0
        ) {
          navigate(`/${routes.recommendationVaOnboarding}${location.search}`);
        } else if (
          !res.organizationProfile.monthlyIncome ||
          res.organizationProfile.monthlyIncome?.length === 0
        ) {
          navigate(`/${routes.incomeOnboarding}${location.search}`);
        } else if (!currentVaStep && !isAdmin) {
          navigate(`/${routes.vaIntroduction}${location.search}`);
        } else if (!res.organization.vaStatus) {
          navigate(`/${routes.vaPaidPortal}${location.search}`);
        } else if (!grantedEmailAccess || isSubscribedEmail) {
          navigate(`/${routes.vaStepsToGetStarted}${location.search}`);
        } else if (!res.organizationProfile?.onboardingGoogleDocId) {
          if (!(hideVaOnboardingVideo === "true")) {
            navigate(`/${routes.vaVideo}${location.search}`);
          } else {
            navigate(`/${routes.vaNiche}${location.search}`);
          }
        } else {
          navigate(`/${routes.vaFaq}${location.search}`);
        }
      } else {
        navigate(`/${routes.pitchOnboarding}${location.search}`);
      }
    } catch (error) {
      handleClerkUserSignUp();
    }
  };

  const handleClerkUserSignUp = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/auth/signup`,
        "POST",
        {},
        {
          referralCode,
          source: referralCode ? "referral" : source,
          signedUpForVa: true,
        },
      );
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setOnboardingForm((prev) => ({
        ...prev,
        name: res.organizationUser.name,
      }));

      setHideCityDialog(true);
      navigate(`/${routes.aboutVaOnboarding}${location.search}`);
      slideInUp();
    } catch (error) {
      // the user needs to be logged out now
      signOut();
      navigate(`/${routes.signIn}`);
      setAlert(
        error?.message || "Unable to fetch information from user.",
        "error",
      );
    } finally {
      setFetchUserLoading(false);
    }
  };

  const saveOnboardingForm = async (onboardingForm: Map, route: string) => {
    const onboardingFormReq = {
      ...onboardingForm,
      source,
      route,
      isVaFlow: true,
    };
    if (route) {
      trackEvent("Onboarding Form - Next Button Pressed", { route });
    }
    await slideOutUp();

    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next")}${location.search}`,
    );

    slideInUp();

    try {
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding`,
        "PUT",
        {},
        onboardingFormReq,
      );

      setOnboardingForm((prev) => ({
        ...prev,
        ...organizationProfile,
      }));
      setProfile({ ...organizationProfile });
    } catch (error) {
      await slideOutDown();
      slideInDown();
      navigate(`${route}${location.search}`);

      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    }
  };

  useEffect(() => {
    fetchUserInfo();
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCompletedNormalFlow =
    onboardingForm?.pitchEmail || onboardingForm?.defaultGoal;
  const paidForVA = isVaPlan && subscription?.isSubscribed;
  const paidThroughSettings = paidForVA && hasCompletedNormalFlow;

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <>
      <Grid container sx={styles.root}>
        <Slide
          in={slideProps.in}
          direction={slideProps.direction}
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeIn,
          }}
          timeout={{
            enter: ENTER_DELAY,
            exit: EXIT_DELAY,
          }}
        >
          <Grid sx={styles.formContainer} item xs={12}>
            <Routes>
              <Route
                path="/about"
                element={
                  <AboutForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/category"
                element={
                  <CategoryForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    isVaOnboarding={true}
                  />
                }
              />
              <Route
                path="/recommendation"
                element={
                  <RecommendationForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    isVaOnboarding={true}
                  />
                }
              />
              <Route
                path="/income"
                element={
                  <IncomeForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route path="/va-niche" element={<VANiche />} />
              <Route
                path="/va-introduction"
                element={
                  <VAIntroduction paidThroughSettings={paidThroughSettings} />
                }
              />
              <Route path="/va-paid-portal" element={<VAPaidPortal />} />
              <Route
                path="/va-steps"
                element={<VASteps paidThroughSettings={paidThroughSettings} />}
              />
              <Route path="/va-video" element={<VAVideo />} />
              <Route path="/va-faq" element={<VAQuestions />} />
            </Routes>
          </Grid>
        </Slide>
      </Grid>

      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </>
  );
};

export default function VAOnboarding() {
  return (
    <SlideAnimationProvider>
      <OnboardingVAInfluencer />
    </SlideAnimationProvider>
  );
}
