import { Box, Grid, Typography } from "@mui/material";
import { BrandsContext, TagLabel } from "contexts/Brands";
import { useContext } from "react";

import { usePageSize } from "utils/usePageSize";

import DiscoverChips from "./Chips";
import styles from "./styles";

interface Props {
  buttons: JSX.Element;
  filtersList: JSX.Element;
  sortFilter: JSX.Element;
  displayedSelectedTags: TagLabel[];
}

const DiscoverFilterDesktop = ({
  filtersList,
  sortFilter,
  buttons,
  displayedSelectedTags,
}: Props) => {
  const { selectedMetadataTags } = useContext(BrandsContext);

  const { navigationWidth } = usePageSize();

  return (
    <>
      <Box sx={styles.root}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item md={1.5} sx={{ pb: 0.5 }}>
            <Typography component="span">
              <strong>Categories</strong>
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md
            sx={styles.filterOptionContainer}
            alignItems="center"
          >
            {filtersList}
            {sortFilter}
          </Grid>
          <Box sx={{ pr: 2 }}>{buttons}</Box>
        </Grid>
      </Box>

      {(selectedMetadataTags.length > 0 ||
        displayedSelectedTags.length > 0) && (
        <Grid container sx={[styles.chipRoot, { width: navigationWidth }]}>
          <Grid item md={1.5} />
          <DiscoverChips
            selectedMetadataTags={selectedMetadataTags}
            selectedTags={displayedSelectedTags}
          />
        </Grid>
      )}
    </>
  );
};

export default DiscoverFilterDesktop;
