import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BentoBrand,
  BentoCategory,
  BentoContact,
  CompensationType,
} from "schemas/dashboard";
import { NONE } from "schemas/forms";
import { CustomEvent } from "schemas/functions";

import Drawer from "components/Drawer";
import { fetcherAuth } from "utils/api";
import { getEnumOptions } from "utils/form";
import { camelToTitleCase, titleCase } from "utils/string";
import { updateList } from "utils/updateLocalState";

import ImageUpload from "./ImageUpload";
import Countries from "./Metadata/Countries";
import MultiBentoCategorySelect from "./MultiBentoCategorySelect";
import MultiCategorySelect from "./MultiCategorySelect";
import { AdminNewBrand } from "./schemas";
import styles from "./styles";

interface NewBrandProps {
  brands: BentoBrand[];
  setBrands: (brands: BentoBrand[]) => void;
  categoriesById: { [key: number]: BentoCategory };
}

type MenuOption = {
  value: string;
  label: string | JSX.Element;
};

export const CATEGORIES = [
  "fashion",
  "men's fashion",
  "women's fashion",
  "activewear",
  "jewelry",
  "eyewear",
  "shoes",
  "bags, watches & other accessories",
  "skincare",
  "makeup & cosmetics",
  "haircare",
  "fragrance",
  "bath & body",
  "other beauty & personal care",
  "luggage / suitcases",
  "travel accessories",
  "travel tours, accommodations & attractions",
  "travel transportation",
  "children's food",
  "children's clothing",
  "baby & maternity",
  "baby, kids, & maternity",
  "apps",
  "technology & electronics",
  "games & entertainment",
  "appliances & household products",
  "home decor",
  "pet products",
  "arts/crafts",
  "sports, fitness & outdoors",
  "health/wellness",
  "retailers & e-commerce platforms",
  "restaurants",
  "food/beverage",
  "education",
  "business services",
  "life services",
  "financial services",
  "vehicle & transportation",
];

const NewBrand = ({ brands, setBrands, categoriesById }: NewBrandProps) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const [brand, setBrand] = useState<AdminNewBrand>({
    brandName: "",
    website: "",
    tags: "",
    mainCategory: "",
    categories: [],
    pricePoint: NONE,
    instagramUsername: "",
    instagramFollowerCount: undefined,
    isArchived: false,
    doNotRecommend: false,
    hasValidatedPartnership: false,
    compensationTypes: [],
    bentoBrandLocations: [],
    mainCategoryIds: [],
    secondaryCategoryIds: [],
  });
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [scrapeLoading, setScrapeLoading] = useState<boolean>(false);
  const [categorizeLoading, setCategorizeLoading] = useState<boolean>(false);
  const [categorizationResult, setCategorizationResult] = useState<string>("");
  const [scrapedContacts, setScrapedContacts] = useState<BentoContact[]>([]);
  const [noneFound, setNoneFound] = useState<boolean>(false);

  const { id } = useParams();

  const handleClose = () => {
    navigate(`/admin/brands`, { replace: true });
  };

  const onChange = (event: CustomEvent, isMultiple?: boolean) => {
    let { name, value } = event.target;
    if (value === NONE) {
      value = isMultiple ? [] : "";
    }
    setBrand((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // TODO, new categories: remove this + all usages once the old categories are no longer used
  const handleOldCategoriesChange = (
    e: CustomEvent,
    value: string[] | null,
  ) => {
    setBrand((prev) => {
      return { ...prev, categories: value || [] };
    });
  };

  const handleCategoriesChange = (categoryIdKey: keyof AdminNewBrand) => {
    return (e: CustomEvent, value: number[] | null) => {
      setBrand((prev) => {
        return {
          ...prev,
          [categoryIdKey]: value,
        };
      });
    };
  };

  const verifyWebsite = async () => {
    setVerifyLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/bento-brands/${brand?.id}/verify`,
        "POST",
      );
      setBrand({
        ...res.bentoBrand,
        mainCategoryIds: brand.mainCategoryIds,
        mainCategories: brand.mainCategoryIds.map(
          (id: number) => categoriesById[id],
        ),
        secondaryCategoryIds: brand.secondaryCategoryIds,
        secondaryCategories: brand.secondaryCategoryIds.map(
          (id: number) => categoriesById[id],
        ),
      });
      updateList(brands, setBrands, res.bentoBrand, "id");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setVerifyLoading(false);
    }
  };

  const saveChanges = async () => {
    setSaveLoading(true);

    const data = new FormData();
    data.append("brand", JSON.stringify(brand));
    if (mainImageFile) {
      data.append("mainImageFile", mainImageFile);
    }
    if (logoFile) {
      data.append("logoFile", logoFile);
    }
    try {
      const res = await fetcherAuth(
        getToken,
        brand?.id
          ? `/api/admin/bento-brands/${brand?.id}`
          : `/api/admin/bento-brands`,
        brand?.id ? "PUT" : "POST",
        {},
        data,
        false,
        true,
      );
      setBrand(res.bentoBrand);
      updateList(brands, setBrands, res.bentoBrand, "id");
      setAlert("Brand saved successfully", "success");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onToggleChange = (event: CustomEvent) => {
    const { name, checked } = event.target;
    setBrand((prev) => {
      return { ...prev, [name]: checked };
    });
  };

  const handleFetchBrand = async () => {
    try {
      setFetchLoading(true);
      const response = await fetcherAuth(
        getToken,
        `/api/admin/bento-brands/${id}`,
        "GET",
      );

      const bentoBrand = response.bentoBrand;
      setBrand(bentoBrand);
    } catch (error) {
      setAlert(error?.message || "Something went wrong", "error");
    } finally {
      setFetchLoading(false);
    }
  };

  const handleScrapeContacts = async () => {
    if (!brand?.id) {
      return;
    }

    try {
      setScrapeLoading(true);
      const response = await fetcherAuth(
        getToken,
        `/api/admin/bento-brands/${brand?.id}/verify-and-find-contacts`,
        "POST",
      );
      if (response.contacts.length === 0) {
        setNoneFound(true);
      } else {
        setScrapedContacts(response.contacts);
      }
    } catch (error) {
      setAlert(error?.message || "Something went wrong", "error");
    } finally {
      setScrapeLoading(false);
    }
  };

  const handleCategorize = async (overwrite: boolean) => {
    if (!brand?.id) {
      return;
    }

    try {
      setCategorizeLoading(true);
      setCategorizationResult("");
      const { bentoBrand, mainCategories, secondaryCategories } =
        await fetcherAuth(
          getToken,
          `/api/admin/bento-brands/${brand?.id}/categorize`,
          "POST",
          {},
          {
            overwrite: overwrite,
          },
        );
      setBrand(bentoBrand);
      setCategorizationResult(
        `Categorized with main categories [${mainCategories.map((c: BentoCategory) => c.name).join(", ")}] and secondary categories [${secondaryCategories.map((c: BentoCategory) => c.name).join(", ")}]`,
      );
    } catch (error) {
      setAlert(error?.message || "Something went wrong", "error");
    } finally {
      setCategorizeLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetchBrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderTextField = (
    key: string,
    helperText?: string,
    required?: boolean,
    type: string = "text",
  ) => {
    return (
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={camelToTitleCase(key)}
          onChange={onChange}
          value={brand[key as keyof AdminNewBrand] || ""}
          name={key}
          required={required}
          type={type}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Grid>
    );
  };

  const renderBooleanField = (key: string) => {
    return (
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              name={key}
              checked={Boolean(brand[key as keyof AdminNewBrand])}
              onChange={onToggleChange}
            />
          }
          label={camelToTitleCase(key)}
        />
      </Grid>
    );
  };

  const renderSelect = (key: string, options: string[]) => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel sx={{ mb: 2 }}>{camelToTitleCase(key)}</InputLabel>

          <Select
            name={key}
            value={brand[key as keyof AdminNewBrand] || NONE}
            onChange={(e) => onChange(e, false)}
            label={camelToTitleCase(key)}
          >
            <MenuItem key={NONE} value={NONE}>
              <em>None</em>
            </MenuItem>

            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {titleCase(option)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderMultipleSelect = (key: string, options: MenuOption[]) => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel sx={{ mb: 2 }}>{camelToTitleCase(key)}</InputLabel>
          <Select
            name={key}
            multiple
            value={brand[key as keyof AdminNewBrand] || []}
            onChange={(e) => onChange(e, true)}
            // @ts-ignore
            renderValue={(selected) => selected?.join(", ")}
            label={camelToTitleCase(key)}
          >
            {options.map((menuOption) => (
              <MenuItem key={menuOption.value} value={menuOption.value}>
                <Checkbox
                  checked={brand?.compensationTypes?.includes(
                    menuOption.value as CompensationType,
                  )}
                />
                <ListItemText primary={menuOption.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <Drawer
      open={true}
      width={{ xs: "auto", md: 800, lg: 1000 }}
      onClose={handleClose}
    >
      <DialogTitle sx={styles.dialogTitleText}>
        <Grid container alignItems="center" justifyContent={"space-between"}>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={styles.actionButton}
            size="small"
          >
            Close
          </Button>

          <Box>
            <Typography component="span" noWrap sx={styles.brandName}>
              {brand?.brandName}
            </Typography>
          </Box>
          <Box>
            {brand?.id && (
              <LoadingButton
                variant="outlined"
                color="secondary"
                sx={{ mr: 1 }}
                onClick={verifyWebsite}
                loading={verifyLoading}
                disableElevation
                size="small"
              >
                Re-Verify Website
              </LoadingButton>
            )}
            <LoadingButton
              variant="contained"
              sx={styles.actionButton}
              onClick={saveChanges}
              loading={saveLoading}
              disableElevation
              size="small"
            >
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </DialogTitle>
      {fetchLoading ? (
        <CircularProgress />
      ) : (
        <DialogContent sx={styles.dialogContent}>
          <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2 }}>
            <ImageUpload
              setAlert={setAlert}
              file={logoFile}
              setFile={setLogoFile}
              imageUrl={brand.logoUrl}
              isRounded
              uploadText="Upload Logo"
            />
            <ImageUpload
              setAlert={setAlert}
              file={mainImageFile}
              allowPaste
              setFile={setMainImageFile}
              imageUrl={brand.mainImageUrl}
              isRounded={false}
              uploadText="Upload Header Image"
            />

            {renderTextField("brandName", "", true)}
            {renderTextField("website", "", true)}
            {brand?.id && renderTextField("tags")}
            {brand?.id && renderSelect("mainCategory", CATEGORIES)}
            {brand?.id && (
              <Grid item xs={12} sm={6}>
                <MultiCategorySelect
                  name="categories"
                  handleAutocompleteChange={handleOldCategoriesChange}
                  categories={brand.categories || []}
                />
              </Grid>
            )}
            {Object.keys(categoriesById).length !== 0 &&
              brand?.mainCategoryIds && (
                <Grid item xs={12} sm={6}>
                  <MultiBentoCategorySelect
                    categoriesById={categoriesById}
                    name="mainCategories"
                    handleAutocompleteChange={handleCategoriesChange(
                      "mainCategoryIds",
                    )}
                    value={brand.mainCategoryIds || []}
                  />
                </Grid>
              )}
            {Object.keys(categoriesById).length !== 0 &&
              brand?.secondaryCategoryIds && (
                <Grid item xs={12} sm={6}>
                  <MultiBentoCategorySelect
                    categoriesById={categoriesById}
                    name="secondaryCategories"
                    handleAutocompleteChange={handleCategoriesChange(
                      "secondaryCategoryIds",
                    )}
                    value={brand.secondaryCategoryIds || []}
                  />
                </Grid>
              )}
            {renderSelect("pricePoint", [
              "budget-friendly",
              "mid-range",
              "premium",
              "luxury",
            ])}
            {renderTextField("countryCode")}
            {renderTextField("instagramUsername")}
            {renderTextField("instagramFollowerCount", "", false, "number")}
            {renderTextField("tiktokUsername")}
            {renderTextField("pinterestUsername")}
            {renderTextField("twitterUsername")}
            {renderTextField("facebookUsername")}
            {renderTextField("linkedinUrl")}
            {renderTextField("youtubeUrl")}
            {renderMultipleSelect(
              "compensationTypes",
              getEnumOptions(CompensationType),
            )}
          </Grid>
          {renderBooleanField("isArchived")}
          {renderBooleanField("doNotRecommend")}
          {renderBooleanField("hasValidatedPartnership")}
          {renderBooleanField("isInternational")}
          {renderBooleanField("hasVerifiedInstagram")}
          {renderBooleanField("worksWithUgc")}
          {renderBooleanField("isLocalOnly")}
          {renderTextField("verificationNotes")}
          {renderBooleanField("excludeFromVerification")}

          {brand?.id && (
            <Countries brand={brand} setBrand={setBrand} setAlert={setAlert} />
          )}

          <Grid item xs={12} my={2}>
            <Typography variant="h6">Categorization</Typography>
            <LoadingButton
              loading={categorizeLoading}
              disabled={!brand?.id}
              variant="outlined"
              onClick={() => handleCategorize(false)}
              style={{ marginRight: 2 }}
            >
              Categorize Brand
            </LoadingButton>
            <LoadingButton
              loading={categorizeLoading}
              disabled={!brand?.id}
              variant="outlined"
              onClick={() => handleCategorize(true)}
              style={{ marginRight: 1 }}
            >
              Categorize Brand (with overwrite)
            </LoadingButton>
            {categorizationResult && (
              <Typography>{categorizationResult}</Typography>
            )}
          </Grid>

          <Grid item xs={12} my={2}>
            <Typography variant="h6">Contacts</Typography>
            <LoadingButton
              loading={scrapeLoading}
              disabled={!brand?.id}
              variant="outlined"
              onClick={handleScrapeContacts}
            >
              Scrape Contacts For This Brand
            </LoadingButton>
            {scrapeLoading && (
              <Typography>
                <em>Scraping contacts.... this might take a few minutes... </em>
              </Typography>
            )}
            {noneFound && (
              <Typography>No contacts found for this brand</Typography>
            )}
            {scrapedContacts.map((contact) => (
              <Typography key={contact.id}>
                {contact.name} - {contact.email}{" "}
                <Link to={`/admin/contacts/${contact.id}`}>View Contact</Link>
              </Typography>
            ))}
          </Grid>
        </DialogContent>
      )}
    </Drawer>
  );
};

export default NewBrand;
