import { isArray } from "lodash";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  BentoBrand,
  BentoBrandCategoryMap,
  BentoBrandMetadataTags,
  DISCOVER_FILTER,
  SearchParams,
} from "schemas/dashboard";

import { DISCOVER_METADATA_TAGS } from "utils/localStorage";
import { makeDeepCopy } from "utils/updateLocalState";

export type TagLabel = {
  name: string; // Medium
  key: string; // md
  params: string; // size
};

export type DiscoverParams = {
  selectedSort: TagLabel;
  selectedCategories: TagLabel[];
  selectedCompanyTypes: TagLabel[];
  selectedCompanySizes: TagLabel[];
  selectedInstagramFollowings: TagLabel[];
  selectedLocations: TagLabel[];
  selectedGeopoints: TagLabel[];
  selectedCompensationTypes: TagLabel[];
};

interface BrandsContextInterface {
  brands: BentoBrand[];
  setBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  clearBrandContext: () => void;
  abortController: MutableRefObject<AbortController | undefined>;
  recommendedBrands: BentoBrand[];
  setRecommendedBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  recommendedBrandsLimit: boolean;
  setRecommendedBrandsLimit: React.Dispatch<SetStateAction<boolean>>;
  recommendedBrandsCursor: number;
  setRecommendedBrandsCursor: React.Dispatch<SetStateAction<number>>;
  recommendedBrandsPage: number;
  setRecommendedBrandsPage: React.Dispatch<SetStateAction<number>>;
  similarBrands: BentoBrand[];
  setSimilarBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  selectedBrand: BentoBrand | null;
  setSelectedBrand: React.Dispatch<SetStateAction<BentoBrand | null>>;
  discoverFilterParams: DiscoverParams;
  removeBrandsFromList: (brand: BentoBrand) => void;
  cursor: number[] | null;
  setCursor: React.Dispatch<SetStateAction<number[] | null>>;
  similarBrandsCursor: number;
  setSimilarBrandsCursor: React.Dispatch<SetStateAction<number>>;
  selectedMetadataTags: BentoBrandMetadataTags[];
  setSelectedMetadataTags: Dispatch<SetStateAction<BentoBrandMetadataTags[]>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  brands: [],
  setBrands: defaultContextMissingFunction,
  clearBrandContext: defaultContextMissingFunction,
  abortController: undefined,
  recommendedBrands: [],
  setRecommendedBrands: defaultContextMissingFunction,
  recommendedBrandsLimit: false,
  setRecommendedBrandsLimit: defaultContextMissingFunction,
  recommendedBrandsCursor: 0,
  setRecommendedBrandsCursor: defaultContextMissingFunction,
  recommendedBrandsPage: 1,
  setRecommendedBrandsPage: defaultContextMissingFunction,
  similarBrands: [],
  setSimilarBrands: defaultContextMissingFunction,
  selectedBrand: null,
  setSelectedBrand: defaultContextMissingFunction,
  discoverFilterParams: {},
  removeBrandsFromList: defaultContextMissingFunction,
  cursor: null,
  setCursor: defaultContextMissingFunction,
  similarBrandsCursor: 0,
  setSimilarBrandsCursor: defaultContextMissingFunction,
  selectedMetadataTags: [],
  setSelectedMetadataTags: defaultContextMissingFunction,
};

// @ts-ignore: We cannot do `useRef` to initialize `abortController`
const BrandsContext = createContext<BrandsContextInterface>(defaultInterface);

interface BrandsProviderProps {
  children: React.ReactNode;
}

const BrandsProvider = ({ children }: BrandsProviderProps) => {
  const [searchParams] = useSearchParams();

  const [selectedMetadataTags, setSelectedMetadataTags] = useState<
    BentoBrandMetadataTags[]
  >([]);
  const [brands, setBrands] = useState<BentoBrand[]>([]);
  const abortController = useRef<AbortController | undefined>(undefined);
  const [recommendedBrands, setRecommendedBrands] = useState<BentoBrand[]>([]);
  const [recommendedBrandsLimit, setRecommendedBrandsLimit] =
    useState<boolean>(false);
  const [recommendedBrandsCursor, setRecommendedBrandsCursor] =
    useState<number>(0);
  const [recommendedBrandsPage, setRecommendedBrandsPage] = useState<number>(1);
  const [similarBrands, setSimilarBrands] = useState<BentoBrand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<BentoBrand | null>(null);

  const [cursor, setCursor] = useState<number[] | null>(null);
  const [similarBrandsCursor, setSimilarBrandsCursor] = useState<number>(0);

  const clearBrandContext = () => {
    setBrands([]);
  };

  const geopoints = searchParams.get(SearchParams.GEOPOINTS_DISCOVER);

  const getParams = (params: string) => {
    const values = searchParams.get(params) || "";

    return values
      .split(",")
      ?.filter((value) => value)
      ?.map((value) => {
        if (params === SearchParams.CATEGORY_DISCOVER) {
          if (value?.includes(":all")) {
            const mainCategory = value?.split(":")[0];
            return {
              key: value,
              name: BentoBrandCategoryMap[mainCategory],
              params,
            };
          } else {
            return { key: value, name: BentoBrandCategoryMap[value], params };
          }
        } else if (
          params in DISCOVER_FILTER &&
          value in DISCOVER_FILTER[params]
        ) {
          return { key: value, ...DISCOVER_FILTER[params][value] };
        } else {
          return { key: "", name: "", params: "" };
        }
      });
  };

  const parseGeopointLabel = () => {
    if (!geopoints) return [];
    const arrayOfGeopoints = JSON.parse(geopoints);
    if (!arrayOfGeopoints && !isArray(arrayOfGeopoints)) return [];

    const formattedLabels: TagLabel[] = [];

    for (const geopoint of arrayOfGeopoints) {
      const parsedGeopoint = {
        key: JSON.stringify(geopoint),
        name: geopoint?.label,
        value: JSON.stringify(geopoint),
        params: SearchParams.GEOPOINTS_DISCOVER,
      };
      formattedLabels.push(parsedGeopoint);
    }
    return formattedLabels;
  };

  const discoverFilterParams = {
    selectedSort: getParams(SearchParams.SORT_DISCOVER)?.[0],
    selectedCategories: getParams(SearchParams.CATEGORY_DISCOVER),
    selectedCompanyTypes: getParams(SearchParams.TYPE_DISCOVER),
    selectedCompanySizes: getParams(SearchParams.SIZE_DISCOVER),
    selectedInstagramFollowings: getParams(SearchParams.FOLLOWING_DISCOVER),
    selectedLocations: getParams(SearchParams.LOCATION_DISCOVER),
    selectedCompensationTypes: getParams(SearchParams.COMPENSATION_DISCOVER),
    selectedGeopoints: parseGeopointLabel(),
  };

  const removeBrandsFromList = (brand: BentoBrand) => {
    const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);
    if (brandQuery) {
      return;
    }
    const isInBrandsIndex = brands.findIndex(
      (b) => Number(b.id) === Number(brand.id),
    );
    if (isInBrandsIndex > -1) {
      setBrands((prev) => {
        const copy = makeDeepCopy(prev);
        copy.splice(isInBrandsIndex, 1);
        return copy;
      });
      return;
    }

    const isInSimilarBrandsIndex = similarBrands.findIndex(
      (b) => Number(b.id) === Number(brand.id),
    );
    if (isInSimilarBrandsIndex > -1) {
      setSimilarBrands((prev) => {
        const copy = makeDeepCopy(prev);
        copy.splice(isInSimilarBrandsIndex, 1);
        return copy;
      });
      setSimilarBrandsCursor((prev) => prev - 1);
    }
  };

  const query = searchParams.get(SearchParams.TAGS_DISCOVER);

  useEffect(() => {
    const existingTags = sessionStorage.getItem(DISCOVER_METADATA_TAGS);

    if (!query) {
      sessionStorage.removeItem(SearchParams.TAGS_DISCOVER);
      setSelectedMetadataTags([]);
    } else if (existingTags) {
      const tags = JSON.parse(existingTags);
      setSelectedMetadataTags(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <BrandsContext.Provider
      value={{
        brands,
        setBrands,
        clearBrandContext,
        abortController,
        recommendedBrands,
        setRecommendedBrands,
        recommendedBrandsLimit,
        setRecommendedBrandsLimit,
        recommendedBrandsCursor,
        setRecommendedBrandsCursor,
        recommendedBrandsPage,
        setRecommendedBrandsPage,
        similarBrands,
        setSimilarBrands,
        selectedBrand,
        setSelectedBrand,
        discoverFilterParams,
        removeBrandsFromList,
        cursor,
        setCursor,
        setSimilarBrandsCursor,
        similarBrandsCursor,
        selectedMetadataTags,
        setSelectedMetadataTags,
      }}
    >
      {children}
    </BrandsContext.Provider>
  );
};

export { BrandsProvider, BrandsContext };
