import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import LocationAutocomplete from "components/LocationAutocomplete";
import { UserLocation } from "components/LocationAutocomplete/schema";
import { isValidHttpUrl } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";
import {
  SocialMediaFields,
  SocialMediaPlatforms,
  getUserType,
  isValidUsername,
  sanitizeInput,
} from "./helpers";

const PLATFORM_DEFAULT = "instagram";

interface AboutFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
}

const AboutForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
}: AboutFormProps) => {
  const [usernameValidation, setUsernameValidation] = useState<
    string | undefined
  >();
  const [portfolioValidation, setPortfolioValidation] = useState<
    string | undefined
  >();
  const location = useLocation();
  const [isUgcValidation, setIsUgcValidation] = useState<string | undefined>();

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  const handleUpdateUserType = (e: CustomEvent) => {
    const { value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (value === "both") {
      copy.isUgcCreator = true;
      copy.isInfluencer = true;
    } else if (value === "ugc") {
      copy.isUgcCreator = true;
      copy.isInfluencer = false;
    } else if (value === "influencer") {
      copy.isInfluencer = true;
      copy.isUgcCreator = false;
    }
    copy.userType = value;
    setIsUgcValidation(undefined);
    setOnboardingForm(copy);
  };

  const handlePlatformChange = (e: CustomEvent) => {
    const { value } = e.target;
    const previousPlatform =
      onboardingForm.primarySocialMediaPlatform || PLATFORM_DEFAULT;
    const previousValue = onboardingForm[`${previousPlatform}Url`];
    if (previousValue) {
      onboardingForm[`${previousPlatform}Url`] = undefined;
    }
    const copy = makeDeepCopy(onboardingForm);
    copy.primarySocialMediaPlatform = value;
    if (previousValue) {
      copy[`${value}Url`] = value;
    }
    setOnboardingForm(copy);
  };

  const handlePortfolioBlurred = (e: CustomEvent) => {
    const { value } = e.target;

    const sanitizedInput = value.trim();

    if (sanitizedInput && !isValidHttpUrl(sanitizedInput)) {
      setPortfolioValidation("Link must start with http:// or https://");
    } else {
      setPortfolioValidation(undefined);
    }
  };

  const handlePortfolioChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const sanitizedInput = value.trim();

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = sanitizedInput;
    setOnboardingForm(copy);

    if (isValidHttpUrl(sanitizedInput)) {
      setPortfolioValidation(undefined);
    }
  };

  const handleSocialMediaBlurred = (e: CustomEvent) => {
    const { value } = e.target;

    const sanitizedInput = sanitizeInput(value.trim());

    if (sanitizedInput && !isValidUsername(sanitizedInput)) {
      setUsernameValidation(
        "Must contain only letters, numbers, underscores, hyphens or periods",
      );
    } else {
      setUsernameValidation(undefined);
    }
  };

  const handleSocialMediaChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const sanitizedInput = sanitizeInput(value.trim());

    const socialMediaUrl = `${SocialMediaFields[name]?.url}${sanitizedInput}`;

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = socialMediaUrl;
    setOnboardingForm(copy);

    if (isValidUsername(value)) {
      setUsernameValidation(undefined);
    }
  };

  const handleLocationChange = (userLocation: UserLocation) => {
    setOnboardingForm((prev) => ({
      ...prev,
      city: userLocation.address,
      location: userLocation.country_code,
      addressComponents: userLocation.address_components,
      longitude: userLocation.longitude,
      latitude: userLocation.latitude,
    }));
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    if (usernameValidation || portfolioValidation || isUgcValidation) {
      return;
    }

    if (!onboardingForm.userType) {
      setIsUgcValidation("Please fill out this field.");
      return;
    }

    const copyForm = { ...onboardingForm };
    let isEdited = false;

    if (
      !onboardingForm.primarySocialMediaPlatform &&
      onboardingForm.instagramUrl
    ) {
      copyForm.primarySocialMediaPlatform = PLATFORM_DEFAULT;
      isEdited = true;
    }

    if (
      copyForm[`${copyForm.primarySocialMediaPlatform}Url`] ===
      SocialMediaFields[`${copyForm.primarySocialMediaPlatform}Url`]?.url
    ) {
      copyForm[`${copyForm.primarySocialMediaPlatform}Url`] = "";
      isEdited = true;
    }

    if (isEdited) {
      setOnboardingForm(copyForm);
    }

    saveOnboardingForm(copyForm, location.pathname);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3, textAlign: "center" }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-user"
          />
          Your Basics
        </Typography>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <FormHelperText sx={styles.helperText}>Name * </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="name"
              placeholder="First Last"
              onChange={handleChange}
              required
              value={onboardingForm?.name || ""}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>Location</FormHelperText>
            <LocationAutocomplete
              handleLocationChange={handleLocationChange}
              userLocation={{
                address: onboardingForm.city,
                longitude: onboardingForm.longitude,
                latitude: onboardingForm.latitude,
                country_code: onboardingForm.location,
                address_components: onboardingForm.addressComponents,
              }}
            />
            <FormHelperText>
              <em>
                specifics help us tailor recommendations, (e.g., zip code,
                neighborhood or city)
              </em>
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid sx={styles.fieldContainer}>
          <FormHelperText error={!!isUgcValidation}>
            Are you a UGC creator, Influencer or Both? *
          </FormHelperText>
          <FormControl error={!!isUgcValidation}>
            <FormLabel id="ugc-creator-label"></FormLabel>
            <RadioGroup
              onChange={handleUpdateUserType}
              name="userType"
              row
              aria-labelledby="ugc-creator-label"
              value={onboardingForm?.userType || getUserType(onboardingForm)}
            >
              <FormControlLabel value={"ugc"} control={<Radio />} label="UGC" />
              <FormControlLabel
                value={"influencer"}
                control={<Radio />}
                label="Influencer"
              />
              <FormControlLabel
                value={"both"}
                control={<Radio />}
                label="Both"
              />
            </RadioGroup>
            {isUgcValidation && (
              <FormHelperText sx={{ ml: 0 }}>{isUgcValidation}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {!isProfilePage && onboardingForm?.isUgcCreator === true && (
          <Grid item xs={12}>
            <FormHelperText sx={styles.helperText}>
              Portfolio / website URL
            </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="portfolioUrl"
              placeholder="https://www.blog.com"
              onChange={handlePortfolioChange}
              onBlur={handlePortfolioBlurred}
              error={!!portfolioValidation}
              helperText={portfolioValidation}
              value={onboardingForm?.portfolioUrl || ""}
            />
          </Grid>
        )}

        {!isProfilePage && onboardingForm?.isInfluencer === true && (
          <>
            <FormHelperText sx={styles.helperText}>
              Primary social channel (where you have the most followers or brand
              partnerships)
            </FormHelperText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md="auto">
                <Select
                  sx={styles.selectSocial}
                  name="primarySocialMediaPlatform"
                  onChange={handlePlatformChange}
                  value={
                    onboardingForm.primarySocialMediaPlatform ||
                    PLATFORM_DEFAULT
                  }
                >
                  {SocialMediaPlatforms.map((platform) => (
                    <MenuItem key={platform.key} value={platform.key}>
                      {platform.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name={`${
                    onboardingForm.primarySocialMediaPlatform ||
                    PLATFORM_DEFAULT
                  }Url`}
                  placeholder="handle"
                  error={!!usernameValidation}
                  helperText={usernameValidation}
                  onChange={handleSocialMediaChange}
                  onBlur={handleSocialMediaBlurred}
                  value={
                    sanitizeInput(
                      onboardingForm?.[
                        `${
                          onboardingForm.primarySocialMediaPlatform ||
                          PLATFORM_DEFAULT
                        }Url`
                      ],
                    ) || ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={styles.linkAdornment}
                        disablePointerEvents
                        position="start"
                      >
                        {
                          SocialMediaFields[
                            `${
                              onboardingForm.primarySocialMediaPlatform ||
                              PLATFORM_DEFAULT
                            }Url`
                          ]?.url
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}

        {!isProfilePage && (
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <Button disableElevation variant="contained" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default AboutForm;
