import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import lodash from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { isDaysInFuture } from "utils/time";
import { trackEvent } from "utils/tracking";

import {
  Day,
  convertTimeToUtc,
  getDateOptions,
  getFutureHours,
} from "./helpers";
import styles from "./styles";

interface Props {
  setDatePicked: Dispatch<SetStateAction<Day>>;
  setTimePicked: Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
    }>
  >;
  setCustomDatePicked: Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
    }>
  >;
  timezone?: string;
  datePicked: Day;
  timePicked: {
    start: string;
    end: string;
  };
  customDatePicked: {
    start: string;
    end: string;
  };
  dateOptions: Day[];
  validHours: string[];
}

const TimeOption = ({
  setDatePicked,
  setTimePicked,
  setCustomDatePicked,
  datePicked,
  timePicked,
  customDatePicked,
  timezone,
  dateOptions,
  validHours,
}: Props) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { setAlert } = useContext(AlertContext);
  const { setEmailSettings } = useContext(OrganizationUserContext);

  const handleTimezoneChange = (
    event: CustomEvent,
    newValue: string | null,
  ) => {
    if (newValue) {
      trackEvent("Timezone Changed", { timezone: newValue });
      setEmailSettings((prev) =>
        prev
          ? {
              ...prev,
              timezone: newValue,
            }
          : prev,
      );

      const validDates = getDateOptions(newValue);
      if (!validDates?.dateOptions?.includes(datePicked)) {
        setDatePicked(validDates?.dateOptions?.[0]);
      }
    }
  };

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    trackEvent("Bulk Schedule Date Selected", { Option: value });
    setDatePicked(value);
    const validHours = getFutureHours(value, timezone || moment.tz.guess());
    if (timePicked.start && !validHours.includes(timePicked.start)) {
      setTimePicked((prev) => ({
        ...prev,
        start: validHours[0],
      }));
    }
    if (timePicked.end && !validHours.includes(timePicked.end)) {
      setTimePicked((prev) => ({
        ...prev,
        end: validHours[validHours.length - 1],
      }));
    }
  };

  const handlePickTime = (e: CustomEvent, name: string) => {
    const { value } = e.target;
    trackEvent("Bulk Schedule Time Selected", { Option: value });
    setTimePicked((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const selectCustomeTime = (e: CustomEvent, name: string) => {
    const { value } = e.target;
    if (value && !isDaysInFuture(value)) {
      setAlert("Please pick a date in future", "error");
      return;
    }
    setCustomDatePicked((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { selectedDate } = convertTimeToUtc(
    datePicked,
    customDatePicked,
    timePicked,
    timezone || moment.tz.guess(),
  );

  return (
    <Grid container alignItems="center">
      <strong>Select Date and Time</strong>
      <Typography variant="body2" sx={styles.subtitle}>
        Bento will automatically spread out your emails through the selected
        timeframe (business days)
      </Typography>

      <Box alignItems="center">
        <Typography component="span" sx={styles.label}>
          Send All {selectedDate}:
        </Typography>
        <Select
          onChange={handleChange}
          value={datePicked}
          sx={styles.noBorder}
          size="small"
        >
          {dateOptions?.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Box sx={styles.option}>{lodash.startCase(item)}</Box>
            </MenuItem>
          ))}
        </Select>
      </Box>

      {datePicked === "customized" && (
        <Grid container alignItems="center" gap={1}>
          <Typography sx={[styles.label, { fontSize: 12 }]}>
            Start Date
          </Typography>
          <Box
            component="input"
            sx={styles.datePicker}
            type="date"
            value={customDatePicked?.start}
            onChange={(e) => selectCustomeTime(e, "start")}
          />
          <Typography sx={[styles.label, { fontSize: 12 }]}>
            End Date
          </Typography>
          <Box
            component="input"
            sx={styles.datePicker}
            type="date"
            value={customDatePicked?.end}
            onChange={(e) => selectCustomeTime(e, "end")}
          />
        </Grid>
      )}

      <Grid container alignItems="center">
        <Typography sx={styles.label}>Select Timezone:</Typography>

        <Autocomplete
          options={moment.tz.names()}
          value={timezone}
          onChange={handleTimezoneChange}
          renderInput={(params) => (
            <TextField {...params} sx={styles.timezonePicker} />
          )}
          disableClearable
          size="small"
        />
      </Grid>

      <Grid container alignItems="center">
        {isMobileScreen ? (
          <Grid item xs={12}>
            <Typography component="span" sx={styles.label}>
              Time Window:
            </Typography>
          </Grid>
        ) : (
          <Typography component="span" sx={styles.label}>
            Time Window:
          </Typography>
        )}

        <Select
          onChange={(e) => handlePickTime(e, "start")}
          value={timePicked?.start}
          sx={[styles.noBorder, isMobileScreen ? { ml: -2 } : {}]}
          displayEmpty
          size="small"
        >
          <MenuItem value="">
            <Box sx={styles.option}>Pick Start Time</Box>
          </MenuItem>
          {validHours?.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Box sx={styles.option}>{item}</Box>
            </MenuItem>
          ))}
        </Select>
        <Typography sx={[styles.label, { fontSize: 12 }]}>to</Typography>
        <Select
          onChange={(e) => handlePickTime(e, "end")}
          value={timePicked?.end}
          sx={styles.noBorder}
          displayEmpty
          size="small"
        >
          <MenuItem value="">
            <Box sx={styles.option}>Pick End Time</Box>
          </MenuItem>
          {validHours?.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Box sx={styles.option}>{item}</Box>
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default TimeOption;
