import { useAuth } from "@clerk/clerk-react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Plans } from "schemas/payments";
import { routes } from "schemas/routes";

import { VA_MONEY_BACK_DEAL } from "constants/influencer";
import { NotInterested } from "features/Home/UpgradeDialog/NotInterested";
import { Plan } from "features/Home/UpgradeDialog/Plan";
import { fetcherAuth } from "utils/api";
import { getSource } from "utils/tracking";
import { useSubscription } from "utils/useSubscription";
import { useTracking } from "utils/useTracking";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import styles from "./styles";

const VAPaidPortal = () => {
  const { getToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOrg } = useContext(OrganizationUserContext);
  const slideContext = useContext(SlideAnimationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackVaStep } = useTracking();
  const source = getSource();

  const { setCurrentOrg } = useContext(OrganizationUserContext);

  const successCheckout = searchParams.get("checkout") === "success";

  const [loading, setLoading] = useState(false);
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { plans } = useContext(SubscriptionContext);
  const { handleCheckout, checkoutLoading } = useSubscription();

  const { subscription, isVaPlan, setSubscription } =
    useContext(SubscriptionContext);
  const isVaUser = subscription?.isSubscribed && isVaPlan;

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const plan = Plans.VA_YEARLY;

  const renewSubscription = async () => {
    setLoading(false);
    try {
      const { subscription } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/renew`,
        "POST",
        {},
      );
      setSubscription(subscription);
      setAlert("Your plan has been successfully renewed!", "success");
    } catch (error) {
      setAlert(
        <>
          Unable to renew subscription. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists.
        </>,
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNotInterested = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding/va-not-interested`,
        "POST",
      );
      setCurrentOrg(res.organization);
      navigate(`/${routes.pitchOnboarding}${location.search}`, {
        replace: true,
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const handleCloseNotInterestedDialog = () => {
    searchParams.delete("checkout");
    setSearchParams(searchParams);
  };

  const handleNext = () => {
    trackVaStep(location.pathname, source);
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next")}${location.search}`,
    );
  };

  const checkout = async (plan: Plans) => {
    handleCheckout(
      plan,
      undefined,
      "_self",
      `/${routes.onboardingVaInfluencer}`,
      "VA Onboarding",
    );
  };

  useEffect(() => {
    if (successCheckout) {
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCheckout]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        📝 Bento's Income Guaranteed Program
      </Typography>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Save hundred of hours and make more money with Bento's Income Guaranteed
        Program.
      </Typography>

      <Box sx={{ maxWidth: 600, display: "block", margin: "auto" }}>
        <Box sx={styles.line}>
          <Box component="i" className="fa-solid fa-check" sx={styles.icon} />{" "}
          Get a personalized email pitch written for you in a 1:1 call.
        </Box>
        <Box sx={styles.line}>
          <Box component="i" className="fa-solid fa-check" sx={styles.icon} />{" "}
          Get hundreds of pitches sent for you every month without lifting a
          finger.
        </Box>
        <Box sx={styles.line}>
          <Box component="i" className="fa-solid fa-check" sx={styles.icon} />
          Get a refund if you don't make ${VA_MONEY_BACK_DEAL} within the first
          12 months from pitches made on Bento.
        </Box>
      </Box>

      {plans && Object.keys(plans)?.length > 0 && (
        <Grid container justifyContent="center">
          <Plan
            key={plan}
            plan={plan as Plans}
            loading={loading || checkoutLoading}
            handleCheckout={checkout}
            renewSubscription={renewSubscription}
            fromOnboarding={true}
          />
        </Grid>
      )}

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
          {!(successCheckout || isVaUser) && (
            <Button
              size="small"
              sx={styles.notInterested}
              onClick={handleNotInterested}
              color="secondary"
            >
              Not Interested
            </Button>
          )}
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          {(successCheckout || isVaUser) && (
            <Button onClick={handleNext} disableElevation variant="contained">
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </Button>
          )}
        </Grid>
      </Grid>

      <NotInterested
        open={searchParams.get("checkout") === "failed"}
        close={handleCloseNotInterestedDialog}
        setAlert={setAlert}
      />
    </>
  );
};

export default VAPaidPortal;
