import { HOME_BODY_COLOR } from "styles/oneOffColor";

const styles = {
  chipsContainer: (breakDiscoverScreen: boolean) => ({
    pt: breakDiscoverScreen ? 0 : { xs: 2, md: 4 },
    pb: 2,
    px: breakDiscoverScreen ? 2 : 0,
    mb: { xs: 2, md: 0 },
    background: HOME_BODY_COLOR,
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
  }),
  selectedChip: {
    borderRadius: 2,
  },
};

export default styles;
