import { Box, Button } from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  COMPANY_TYPES,
  COMPENSATION_TYPES,
  FOLLOWING_SIZES,
  SORTS,
  SearchParams,
} from "schemas/dashboard";
import { ALL } from "schemas/forms";

import { accessToVaFeature } from "utils/influencer";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";
import { useSearchBrand } from "utils/useSearchBrand";

import AutomationVA from "./AutomationVA";
import CompanySizeFilter from "./CompanySizeFilter";
import LocationFilter from "./LocationFilter";
import SelectFilter from "./SelectFilter";
import DiscoverFilterDesktop from "./desktop";
import { companyTypeIcons } from "./helpers";
import DiscoverFilterMobile from "./mobile";
import styles from "./styles";

interface Props {
  setOpenMobileCategoryFilter: Dispatch<SetStateAction<boolean>>;
}

export default function DiscoverFilter({ setOpenMobileCategoryFilter }: Props) {
  const { setOpen } = useContext(UserRequestsContext);
  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { isVaPlan, subscription } = useContext(SubscriptionContext);

  const { addCategoryQueryToSearch, hasTags, selectedTags } = useSearchBrand();
  const { selectedMetadataTags } = useContext(BrandsContext);

  const { breaksDiscoverScreen } = usePageSize();

  const hasAccessToVa = accessToVaFeature(currentOrg, subscription, isVaPlan);

  const [close, setClose] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  // Hide `All` tag and sort params.
  const displayedSelectedTags = selectedTags?.filter(
    (tag) =>
      tag?.name &&
      tag?.params !== SearchParams.SORT_DISCOVER &&
      tag?.key !== ALL,
  );

  const isOptionSelected = (key: string, params: string) => {
    return Boolean(
      selectedTags?.find((x) => x?.key === key && x.params === params),
    );
  };

  const handleAddOption = (name: string, value: string) => {
    addCategoryQueryToSearch(name, value);
    setClose(true);
  };

  const selectedOptionIcon = (
    <Box
      component="i"
      className="fa-regular fa-check"
      sx={{ mr: 2, color: "success.main" }}
    />
  );

  const sortFilter = (
    <SelectFilter
      label={"Sort By"}
      options={SORTS}
      params={SearchParams.SORT_DISCOVER}
      setClose={setClose}
      close={close}
      isOptionSelected={isOptionSelected}
      selectedOptionIcon={selectedOptionIcon}
    />
  );

  const openCategoryFilter = () => {
    trackEvent("Cateogry Filter Toggled on Mobile View");
    setOpenMobileCategoryFilter((prev) => !prev);
  };

  const openRequest = () => {
    trackEvent("Request Button Clicked");
    if (!canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }
    setOpen(true);
  };

  const filtersList = (
    <>
      <SelectFilter
        label={"Type"}
        options={COMPANY_TYPES}
        params={SearchParams.TYPE_DISCOVER}
        setClose={setClose}
        close={close}
        isOptionSelected={isOptionSelected}
        selectedOptionIcon={selectedOptionIcon}
        iconsMap={companyTypeIcons}
      />
      <LocationFilter
        handleAddOption={handleAddOption}
        close={close}
        setClose={setClose}
        selectedOptionIcon={selectedOptionIcon}
        isOptionSelected={isOptionSelected}
      />
      <CompanySizeFilter
        handleAddOption={handleAddOption}
        close={close}
        setClose={setClose}
        selectedOptionIcon={selectedOptionIcon}
        isOptionSelected={isOptionSelected}
      />
      <SelectFilter
        label={"Instagram Following"}
        options={FOLLOWING_SIZES}
        params={SearchParams.FOLLOWING_DISCOVER}
        setClose={setClose}
        close={close}
        isOptionSelected={isOptionSelected}
        selectedOptionIcon={selectedOptionIcon}
      />
      {hasAccessToVa && (
        <SelectFilter
          label={"Compensation Types"}
          options={COMPENSATION_TYPES}
          params={SearchParams.COMPENSATION_DISCOVER}
          setClose={setClose}
          close={close}
          isOptionSelected={isOptionSelected}
          selectedOptionIcon={selectedOptionIcon}
        />
      )}
    </>
  );

  const buttons = (
    <>
      {hasAccessToVa && <AutomationVA />}
      <Button
        size="small"
        variant="contained"
        sx={styles.requestButton}
        disableElevation
        onClick={openRequest}
      >
        <Box component="i" className="fa-regular fa-message" sx={{ mr: 1 }} />
        {breaksDiscoverScreen ? "Request" : "Request Brand"}
      </Button>
    </>
  );

  const totalDisplay =
    Number(displayedSelectedTags?.length) +
    Number(selectedMetadataTags?.length);

  if (breaksDiscoverScreen) {
    return (
      <DiscoverFilterMobile
        hasTags={hasTags}
        openCategoryFilter={openCategoryFilter}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        totalDisplay={totalDisplay}
        buttons={buttons}
        filtersList={filtersList}
        sortFilter={sortFilter}
      />
    );
  } else {
    return (
      <DiscoverFilterDesktop
        buttons={buttons}
        filtersList={filtersList}
        sortFilter={sortFilter}
        displayedSelectedTags={displayedSelectedTags}
      />
    );
  }
}
