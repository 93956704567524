import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { BentoBrand, MetadataType, SearchParams } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { DiscoverSearchChipColor } from "utils/color";
import { useSearchBrand } from "utils/useSearchBrand";

import EditCategoryDialog from "./EditCategory";
import { CategoryEdit } from "./schema";
import styles from "./styles";

interface Props {
  selectedBrand?: BentoBrand | null;
  header: string;
  stringValue?: string;
}

const BrandAnnotation = ({ header, selectedBrand, stringValue }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { handleClickOnTag } = useSearchBrand();
  const { getToken } = useAuth();
  let initialized = false;

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editCategories, setEditCategories] = useState<CategoryEdit>({
    categories: [],
    suggestedCategories: [],
  });

  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const closeDialog = () => {
    setOpenCategoryDialog(false);
    setEditCategories({ categories: [], suggestedCategories: [] });
  };

  const handleEditCategories = () => {
    setEditCategories({
      categories: selectedBrand?.categories ?? [],
      suggestedCategories: selectedBrand?.categories ?? [],
    });
    setOpenCategoryDialog(true);
  };

  const renderChip = (
    index: number,
    value: string,
    backgroundColor: string,
    type: MetadataType,
  ) => {
    return (
      <Chip
        key={index}
        size="small"
        sx={{
          backgroundColor,
          my: 0.5,
        }}
        label={value.replaceAll("_", " ")}
        onClick={(e) =>
          handleClickOnTag(e, {
            type,
            value,
          })
        }
      />
    );
  };

  let value: JSX.Element[] | JSX.Element | undefined | string = <></>;
  if (header === "Category") {
    initialized = true;
    value = selectedBrand?.categories?.map((category, index) =>
      renderChip(
        index,
        category,
        DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
        MetadataType.tags,
      ),
    );
  } else if (header === "Price Point" && selectedBrand?.pricePoint) {
    initialized = true;
    value = renderChip(
      0,
      selectedBrand?.pricePoint,
      DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
      MetadataType.tags,
    );
  } else if (header === "Company Size" && selectedBrand?.size) {
    initialized = true;
    value = renderChip(
      0,
      selectedBrand?.size,
      DiscoverSearchChipColor[SearchParams.SIZE_DISCOVER],
      MetadataType.size,
    );
  } else if (stringValue) {
    initialized = true;
    value = stringValue;
  }

  if (!selectedBrand || !initialized) return <></>;

  const handleSubmitSuggestedCategories = async () => {
    if (editCategories?.suggestedCategories?.length === 0) {
      setAlert("Please add at least one category.", "error");
      return;
    }

    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-suggestions/${selectedBrand.id}`,
        "POST",
        {},
        {
          fieldName: "categories",
          currentValue: editCategories.categories.join(", "),
          suggestedValue: editCategories.suggestedCategories.join(", "),
          label: "Categories",
        },
      );
      setAlert("Your suggestion has been submitted.", "success");
      closeDialog();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  return (
    <Grid item xs={12}>
      <Typography gutterBottom>
        <Box component="span" sx={styles.key}>
          {header}:{" "}
        </Box>{" "}
        <Box sx={styles.value} component="span">
          {value}{" "}
          {header === "Category" && (
            <Tooltip title="Edit this categorization">
              <IconButton sx={{ fontSize: 12 }} onClick={handleEditCategories}>
                <Box component="i" className="fa-regular fa-edit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Typography>

      <EditCategoryDialog
        open={openCategoryDialog}
        handleClose={closeDialog}
        editCategories={editCategories}
        setEditCategories={setEditCategories}
        handleSubmit={handleSubmitSuggestedCategories}
      />
    </Grid>
  );
};

export default BrandAnnotation;
