import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { BentoBrandCategoryMap } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { sanitize } from "utils/string";

import { CategoryEdit } from "../schema";

interface Props {
  open: boolean;
  handleClose: () => void;
  editCategories: CategoryEdit;
  setEditCategories: (editCategories: CategoryEdit) => void;
  handleSubmit: (e: CustomEvent) => void;
}

const EditCategoryDialog = ({
  open,
  handleClose,
  editCategories,
  setEditCategories,
  handleSubmit,
}: Props) => {
  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    setEditCategories({
      ...editCategories,
      suggestedCategories: value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h6" textAlign="center">
          Edit This Categorization
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          By submitting edits to brand information, you are helping to improve
          everyone's experience on Bento. Thank you for your contribution!
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="edit-categories-label">Current Categories</InputLabel>
          <Select
            labelId="edit-categories-label"
            id="edit-categories"
            multiple
            value={editCategories.suggestedCategories}
            onChange={handleChange}
            input={<OutlinedInput label="Current Categories" />}
            renderValue={(selected) => selected.join(", ")}
            size="small"
            fullWidth
          >
            {Object.values(BentoBrandCategoryMap).map((key) => (
              <MenuItem key={key} value={sanitize(key)}>
                <Checkbox
                  checked={editCategories.suggestedCategories.includes(
                    sanitize(key),
                  )}
                />
                <ListItemText primary={key} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          disableElevation
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryDialog;
