import moment from "moment";

import { addBusinessDays } from "utils/time";

type DateTimePicker = {
  start: string;
  end: string;
};

export enum Day {
  today = "today",
  tomorrow = "tomorrow",
  this_week = "this week",
  next_week = "next week",
  customized = "customized",
}

export const getFutureHours = (datePicked: string, timezone: string) => {
  const hoursArray = [];

  const businessStartTime = 7; // 7 AM
  const businessEndTime = 20; // 7 PM
  const hoursPerHalfDay = 12;

  const _getCurrentHour = (timezone: string) => {
    if (datePicked === Day.today || datePicked === Day.this_week) {
      const currentDate = moment().tz(timezone);
      let currentHour = currentDate.hour();
      let currentMinutes = currentDate.minute();

      // If minutes are greater than 0, move to the next hour
      if (currentMinutes > 0) {
        currentHour++;
      }
      return currentHour;
    } else {
      return businessStartTime;
    }
  };

  const currentHour = _getCurrentHour(timezone);

  for (let i = currentHour; i < businessEndTime; i++) {
    // Convert to 12-hour format
    const hour =
      i % hoursPerHalfDay === 0 ? hoursPerHalfDay : i % hoursPerHalfDay;
    const period = i >= hoursPerHalfDay ? "PM" : "AM";
    const timeString = `${hour}:00 ${period}`;
    hoursArray.push(timeString);
  }
  return hoursArray;
};

export const getDefaultHours = (hours: string[]) => {
  let start = "10:00 AM";
  let end = "4:00 PM";
  if (!hours?.includes("10:00 AM")) {
    start = hours?.[0];
  }
  if (!hours?.includes("4:00 PM")) {
    end = hours?.[hours?.length - 1];
  }
  return { defaultStart: start, defaultEnd: end };
};

export const getDateStartAndEndTime = (
  datePicked: string,
  customDatePicked: DateTimePicker,
  timezone: string,
) => {
  let start: any = moment();
  let end: any = moment();
  if (datePicked === Day.tomorrow) {
    start = addBusinessDays(1);
    end = addBusinessDays(1);
  } else if (datePicked === Day.this_week) {
    if (_isAfterBusinessHour(timezone)) {
      start = addBusinessDays(1);
    }
    end = moment().day(4);
  } else if (datePicked === Day.next_week) {
    start = moment().day(1 + 7);
    end = moment().day(4 + 7);
  } else if (datePicked === Day.customized) {
    start = moment(customDatePicked?.start);
    end = moment(customDatePicked?.end);
  }
  return { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") };
};

export const convertTimeToUtc = (
  datePicked: string,
  customDatePicked: DateTimePicker,
  timePicked: DateTimePicker,
  timezone: string,
) => {
  const { start, end } = getDateStartAndEndTime(
    datePicked,
    customDatePicked,
    timezone,
  );
  const startInUtc = moment
    .tz(`${start} ${timePicked?.start}`, "YYYY-MM-DD hh:mm A", timezone)
    .utc();
  const endInUtc = moment
    .tz(`${end} ${timePicked?.end}`, "YYYY-MM-DD hh:mm A", timezone)
    .utc();

  const startDate = startInUtc.format("YYYY-MM-DD");
  const endDate = endInUtc.format("YYYY-MM-DD");
  const startTime = startInUtc.format("HH:mm");
  const endTime = endInUtc.format("HH:mm");

  // String to display on the Select dropdown
  let selectedDate = "";
  const selectStartDate = startInUtc.format("MMM DD");
  const selectEndDate = endInUtc.format("MMM DD");

  if (datePicked !== Day.customized) {
    if (startDate === endDate) {
      selectedDate = `(${selectStartDate})`;
    } else {
      selectedDate = `(${selectStartDate} - ${selectEndDate})`;
    }
  }

  return { startDate, endDate, startTime, endTime, selectedDate };
};

export const getDateOptions = (timezone: string) => {
  // Valid date to send email is Monday - Thursday.

  let defaultOption = Day.next_week;
  const today = moment().tz(timezone).day();

  // 1 is Monday, 2 is Tuesday, 3 is Wednesday..etc.
  const optionsByWeekday: { [key: number]: Day[] } = {
    1: [Day.today, Day.tomorrow, Day.this_week],
    2: [Day.today, Day.tomorrow, Day.this_week],
    3: [Day.today, Day.tomorrow, Day.this_week],
    4: [Day.today],
    5: [Day.today],
  };

  let dateOptions = today in optionsByWeekday ? optionsByWeekday[today] : [];

  // If it's already past 6 pm, remove `today` as an option since it's too late to send email.
  if (dateOptions?.includes(Day.today)) {
    if (_isAfterBusinessHour(timezone)) {
      dateOptions.shift();
    }
  }

  if (dateOptions?.includes(Day.this_week) && today === 3) {
    dateOptions = dateOptions.filter((option) => option !== Day.this_week);
  }

  if (dateOptions?.includes(Day.this_week)) {
    defaultOption = Day.this_week;
  }

  dateOptions = [...dateOptions, Day.next_week, Day.customized];
  return { dateOptions, defaultOption };
};

const _isAfterBusinessHour = (timezone: string) => {
  const now = moment().tz(timezone);
  const sixPM = moment().tz(timezone).set({ hour: 18, minute: 0, second: 0 });
  return now.isAfter(sixPM);
};
