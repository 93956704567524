import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { SubscriptionContext } from "contexts/Subscription";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { CustomEvent } from "schemas/functions";

import { accessToVaFeature } from "utils/influencer";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";

interface Props {
  open: boolean;
  handleClose: () => void;
  isBulkMode?: boolean;
  setIsBulkMode?: Dispatch<SetStateAction<boolean>>;
}
const NewCollectionDialog = ({
  open,
  handleClose,
  isBulkMode,
  setIsBulkMode,
}: Props) => {
  const { moveDraftsFromOneListToAnother } = useDrafts();
  const { createOrUpdateCollection, deleteCollection } = useCollection();
  const { selectedCollection, setSelectedCollectionId } = useContext(
    SavedBrandCollectionsContext,
  );
  const { bulkBrands, bulkMoveTo, moveLoading } = useContext(SavedBrandContext);

  const collectionId = selectedCollection?.id;
  const isEdit = !isBulkMode && collectionId && collectionId > 0;
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isVaPlan } = useContext(SubscriptionContext);
  const hasAccessToVa = accessToVaFeature(currentOrg, subscription, isVaPlan);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [excludeFromDefaultVa, setExcludeFromDefaultVa] = useState(
    selectedCollection?.excludeFromDefaultVa,
  );

  const [name, setName] = useState("");

  const handleChange = (e: CustomEvent) => {
    setName(e.target.value);
  };

  const create = async () => {
    setLoading(true);
    const newCollection = await createOrUpdateCollection(
      name,
      undefined,
      isBulkMode,
      excludeFromDefaultVa,
    );
    if (newCollection && isBulkMode) {
      moveDraftsFromOneListToAnother(
        bulkBrands,
        newCollection.id,
        selectedCollection?.id || 0,
      );
      setSelectedCollectionId(newCollection.id);
      bulkMoveTo(newCollection.id);
    }
    setLoading(false);
    _reset();
  };

  const update = async () => {
    setLoading(true);
    const newCollection = await createOrUpdateCollection(
      name,
      collectionId,
      false,
      excludeFromDefaultVa,
    );
    setSelectedCollectionId(newCollection?.id ?? null);
    setLoading(false);
    _reset();
  };

  const handleDelete = async () => {
    if (!collectionId) return;
    setDeleteLoading(true);
    await deleteCollection(collectionId);
    setSelectedCollectionId(null);
    setDeleteLoading(false);
    handleClose();
  };

  const handleUpdateEnqueued = () => {
    setExcludeFromDefaultVa((prev) => !prev);
  };

  const _reset = () => {
    handleClose();
    setName("");
    if (setIsBulkMode) setIsBulkMode(false);
  };

  useEffect(() => {
    if (isEdit) {
      setName(selectedCollection?.name);
    } else {
      setName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection?.id, isEdit]);

  useEffect(() => {
    setExcludeFromDefaultVa(selectedCollection?.excludeFromDefaultVa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection?.id]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isEdit ? `Update ${selectedCollection.name}` : "New Collection"}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">
          {isEdit ? (
            <>
              To update, fill out a new collection name and press{" "}
              <strong>Save</strong>. To delete a collection, press{" "}
              <strong>Delete</strong>.
            </>
          ) : (
            <>
              Create new collection by filling out the name below and press{" "}
              <strong>Create</strong>
            </>
          )}
        </Typography>
        <TextField
          onChange={handleChange}
          fullWidth
          placeholder="Home Brands NYC"
          label="Collection Name"
          sx={{ my: 2, minWidth: { xs: 300, md: 400 } }}
          value={name}
        />
        {!selectedCollection?.isDefaultForVa && hasAccessToVa && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={handleUpdateEnqueued}
                checked={excludeFromDefaultVa}
              />
            }
            label="Exclude brands in this collection from Upcoming Pitches"
          />
        )}
      </DialogContent>
      <Grid container justifyContent="space-between" sx={{ px: 2, py: 1 }}>
        {isEdit ? (
          <Tooltip
            title={
              selectedCollection?.isDefaultForVa
                ? "This collection is required for your virtual assistant and cannot be deleted"
                : ""
            }
          >
            <div>
              <LoadingButton
                loading={deleteLoading}
                sx={{ color: "error.main" }}
                onClick={handleDelete}
                disabled={selectedCollection?.isDefaultForVa}
              >
                Delete
              </LoadingButton>
            </div>
          </Tooltip>
        ) : (
          <Box />
        )}
        <Box>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={loading || moveLoading}
            onClick={() => (isEdit ? update() : create())}
            disabled={!(name?.trim()?.length > 0)}
          >
            {isEdit ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default NewCollectionDialog;
