import { OrganizationProfile } from "schemas/dashboard";
import { Map } from "schemas/functions";
import {
  INSTAGRAM_USERNAME_URL,
  TIKTOK_USERNAME_URL,
  TWITCH_USERNAME_URL,
  YOUTUBE_USERNAME_URL,
} from "schemas/social";

export const hasSocialMedia = (onboardingForm: OrganizationProfile) => {
  if (
    !onboardingForm?.instagramUrl &&
    !onboardingForm?.tiktokUrl &&
    !onboardingForm?.youtubeUrl &&
    !onboardingForm?.twitchUrl
  ) {
    return false;
  }
};

export const SocialMediaPlatforms = [
  {
    name: "Instagram",
    key: "instagram",
  },
  {
    name: "TikTok",
    key: "tiktok",
  },
  {
    name: "YouTube",
    key: "youtube",
  },
  {
    name: "Twitch",
    key: "twitch",
  },
];

export const SocialMediaFields: {
  [key: string]: { url: string };
} = {
  instagramUrl: {
    url: INSTAGRAM_USERNAME_URL,
  },
  tiktokUrl: {
    url: TIKTOK_USERNAME_URL,
  },
  youtubeUrl: {
    url: YOUTUBE_USERNAME_URL,
  },
  twitchUrl: {
    url: TWITCH_USERNAME_URL,
  },
  portfolioUrl: {
    url: "https://",
  },
};

export const sanitizeInput = (str: string | undefined) => {
  const inputStr = str?.trim();
  if (!inputStr) {
    return "";
  }
  const regex =
    /(?:instagram\.com|tiktok\.com|youtube\.com|twitch\.tv)\/(?:_u\/|@)?([^/?\s]*)(\/|\?|$)/;
  const matches = regex.exec(inputStr);
  const extractedUsername = matches && matches.length > 1 ? matches[1] : null;
  if (extractedUsername !== null) return extractedUsername;
  if (inputStr.startsWith("@")) {
    return inputStr.slice(1);
  }

  const urlRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?\s]*)/;
  const urlMatches = urlRegex.exec(inputStr);
  const extractedUrl =
    urlMatches && urlMatches.length > 1 ? urlMatches[1] : null;
  if (extractedUrl !== null) return extractedUrl;
  return inputStr;
};

export const isValidUsername = (str: string | undefined) => {
  if (!str) {
    return false;
  }
  const regex = /^[a-zA-Z0-9_.-]+$/;
  return regex.test(str);
};

export const getUserType = (onboardingForm: Map) => {
  if (onboardingForm?.isUgcCreator && onboardingForm?.isInfluencer) {
    return "both";
  } else if (onboardingForm?.isUgcCreator) {
    return "ugc";
  } else if (onboardingForm?.isInfluencer) {
    return "influencer";
  } else {
    return "";
  }
};
