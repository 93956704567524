import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { BentoBrand, EmailSequenceMap, SendOption } from "schemas/dashboard";
import { ALL } from "schemas/forms";

import { getPageName } from "constants/trackingProps";
import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { UNCATEGORIZED } from "utils/templates";

import MainDialogBody from "./MainDialogBody";
import MainDialogHeader from "./MainDialogHeader";
import PreviewEmail from "./PreviewEmail";
import TimeSelection from "./TimeSelection";
import {
  Day,
  convertTimeToUtc,
  getDateOptions,
  getDefaultHours,
  getFutureHours,
} from "./TimeSelection/helpers";
import { CategoryMap, DialogScreen, EmailSequenceBe, Preview } from "./schema";

interface Props {
  open: boolean;
  handleClose: () => void;
  resetSelectedBrands: (templateIds?: number[]) => void;
  presetEmailSequenceMap: EmailSequenceMap;
  collectionId?: number;
  fromTrackingTable?: boolean;
  removeFromBulkBrands: (bentoBrand: BentoBrand) => void;
}

export default function BulkSendDialog({
  open,
  handleClose,
  collectionId,
  resetSelectedBrands,
  presetEmailSequenceMap,
  fromTrackingTable = false,
  removeFromBulkBrands,
}: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const {
    currentOrg,
    emailSettings,
    allEmailSettingsCategories,
    setAllEmailSettingsCategories,
  } = useContext(OrganizationUserContext);
  const timezone = emailSettings?.timezone || moment.tz.guess();

  const { getToken } = useAuth();
  const { setUpgradeDialogSource, subscription, setSubscription } =
    useContext(SubscriptionContext);
  const { emailHealth, setOpenIntegrationDialog, fetchIntegrationHealth } =
    useContext(UserIntegrationsContext);
  const { dateOptions, defaultOption } = getDateOptions(timezone);

  // Template variables
  const [sendOption, setSendOption] = useState<SendOption | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<CategoryMap>({});

  // Time and date range selection
  const [datePicked, setDatePicked] = useState<Day>(defaultOption);
  const [customDatePicked, setCustomDatePicked] = useState({
    start: "",
    end: "",
  });
  const validHours = getFutureHours(datePicked, timezone);
  const { defaultStart, defaultEnd } = getDefaultHours(validHours);
  const [timePicked, setTimePicked] = useState({
    start: defaultStart,
    end: defaultEnd,
  });
  const [lastUsedTemplateSelected, setLastUsedTemplateSelected] =
    useState(false);
  const [preview, setPreview] = useState<Preview | undefined>(undefined);

  const close = () => {
    handleClose();
    setCategories({});
    setSendOption(undefined);
    setEditList("");
    setPreview(undefined);
  };

  const getTotalBrands = () => {
    if (Object.keys(categories).length === 0) {
      return Object.keys(presetEmailSequenceMap)?.length;
    } else {
      return Object.values(categories).reduce(
        (acc, category) => acc + category.emailSequences.length,
        0,
      );
    }
  };
  const totalBrands = getTotalBrands();
  const [editList, setEditList] = useState<string>("");

  const startSend = async () => {
    // Check Paywall
    if (
      subscription?.remainingMessages &&
      totalBrands > subscription?.remainingMessages
    ) {
      setAlert(
        `It looks like you're trying to send ${totalBrands} messages, but your current plan allows only ${subscription?.remainingMessages} more this billing cycle. Please reduce the number of messages or upgrade your plan to send more.`,
        "warning",
      );
      setUpgradeDialogSource("Hits Bulk Send Limit");
      return;
    }

    if (!emailHealth) {
      setOpenIntegrationDialog(true);
      return;
    }

    if (
      datePicked === Day.customized &&
      !(customDatePicked?.end && customDatePicked?.start)
    ) {
      setAlert("Please pick a start and end date", "error");
      return;
    }

    if (!(timePicked?.start && timePicked?.end)) {
      setAlert("Please pick a start and end time", "error");
      return;
    }

    const indexOfStart = validHours?.findIndex((x) => x === timePicked?.start);
    const indexOfEnd = validHours?.findIndex((x) => x === timePicked?.end);
    if (datePicked === Day.today && indexOfStart >= indexOfEnd) {
      setAlert("Start time must be before end time", "error");
      return;
    }

    const { startDate, endDate, startTime, endTime } = convertTimeToUtc(
      datePicked,
      customDatePicked,
      timePicked,
      timezone,
    );

    setLoading(true);

    // Convert to an array to be used on the BE
    const emailSequences: EmailSequenceBe[] = [];
    for (const bentoBrandId in presetEmailSequenceMap) {
      const sequence = presetEmailSequenceMap[bentoBrandId];
      const category =
        sendOption === SendOption.by_category ? sequence.category : ALL;

      if (!categories[category]?.fallbackTemplates) {
        // brand was removed from original list
        continue;
      }
      emailSequences.push({
        bentoBrandId: parseInt(bentoBrandId),
        sequenceUuid: lastUsedTemplateSelected
          ? sequence.sequenceUuid
          : undefined,
        fallbackTemplateIds: categories[category]?.fallbackTemplates?.map(
          (t) => t.id as number,
        ),
      });
    }

    const body = {
      startDate,
      endDate,
      startTime,
      endTime,
      emailSequences,
      collectionId,
      fromTrackingTable,
      source: getPageName(),
    };

    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/bulk-schedule`,
        "POST",
        {},
        body,
      );
      setAlert(
        "Successfully scheduled your emails. You can see them in Inbox page, under Scheduled tab. It might take a few minutes see all emails",
        "success",
      );
      setSubscription((prev) => {
        if (prev) {
          prev["remainingMessages"] = Math.max(
            (prev?.remainingMessages || 0) - totalBrands,
            0,
          );
        }
        return prev;
      });
      updateEmailSettings(categories);
      close();
      resetSelectedBrands();
    } catch (error) {
      if (error?.message?.includes("Google integration")) {
        setOpenIntegrationDialog(true);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateEmailSettings = async (categories: CategoryMap) => {
    const emailSettingsAll = [];
    for (const category in categories) {
      if (category !== UNCATEGORIZED) {
        const body = {
          templateIds: categories[category]?.fallbackTemplates?.map(
            (t) => t.id as number,
          ),
          category,
        };
        emailSettingsAll.push(body);
      }
    }
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/email-settings/bulk-send`,
        "PUT",
        {},
        {
          emailSettings: emailSettingsAll,
          timezone,
        },
      );
      setAllEmailSettingsCategories(res.emailSettings);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const previewTemplate = async (
    templates: Template[],
    bentoBrand: BentoBrand,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach/preview-quick-send`,
        "POST",
        {},
        {
          bentoBrandId: bentoBrand?.id,
          emailTemplates: templates,
        },
        true,
        false,
        true,
      );
      const json = await res.json();
      const { outreachMessages } = json.preview;
      setPreview({
        bentoBrand,
        outreachMessages,
        templates,
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const renderTimePickedOption = () => {
    return (
      <TimeSelection
        setDatePicked={setDatePicked}
        setTimePicked={setTimePicked}
        setCustomDatePicked={setCustomDatePicked}
        timezone={timezone}
        datePicked={datePicked}
        timePicked={timePicked}
        customDatePicked={customDatePicked}
        dateOptions={dateOptions}
        validHours={validHours}
      />
    );
  };

  const sortBrandByCategories = (
    emailSequences: EmailSequenceMap,
    sendOption: SendOption,
  ) => {
    const categories: CategoryMap = {};
    for (const emailSequence of Object.values(emailSequences)) {
      const category =
        sendOption === SendOption.by_category ? emailSequence.category : ALL;
      if (!categories[category]) {
        categories[category] = {
          emailSequences: [],
          fallbackTemplates: [],
        };
      }
      categories[category]["emailSequences"].push(emailSequence);
    }
    if (sendOption === SendOption.by_category) {
      // Sort categories key alphabetically
      const sortedKeys = Object.keys(categories).sort();
      const sortedCategories: CategoryMap = {};
      for (const key of sortedKeys) {
        sortedCategories[key] = categories[key];
      }
      setCategories(sortedCategories);
    } else {
      setCategories(categories);
    }
  };

  const pickOption = (option: SendOption) => {
    if (
      option === SendOption.by_category &&
      allEmailSettingsCategories.length === 0
    ) {
      fetchEmailSettingsWithCategories();
    }
    sortBrandByCategories(presetEmailSequenceMap, option);
    setSendOption(option);
  };

  const handleBack = (screen: DialogScreen) => {
    if (screen === DialogScreen.send_option) {
      setSendOption(undefined);
      setCategories({});
    } else if (screen === DialogScreen.edit_brands_list) {
      setEditList("");
    } else if (screen === DialogScreen.preview_email) {
      setPreview(undefined);
    }
  };

  const fetchEmailSettingsWithCategories = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/email-settings`,
        "GET",
      );
      setAllEmailSettingsCategories(res.emailSettings);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    if (emailHealth) {
      setOpenIntegrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
      if (fromTrackingTable) setLastUsedTemplateSelected(true);
      fetchEmailSettingsWithCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <MainDialogHeader
          sendOption={sendOption}
          editList={editList}
          totalBrands={totalBrands}
          outreachMessages={preview?.outreachMessages || []}
          handleBack={handleBack}
        />

        {preview?.outreachMessages && preview?.outreachMessages?.length > 0 ? (
          <PreviewEmail
            previewTemplate={previewTemplate}
            preview={preview}
            setPreview={setPreview}
          />
        ) : (
          <MainDialogBody
            editList={editList}
            setEditList={setEditList}
            categories={categories}
            setCategories={setCategories}
            pickOption={pickOption}
            sendOption={sendOption}
            renderTimePickedOption={renderTimePickedOption}
            fromTrackingTable={fromTrackingTable}
            lastUsedTemplateSelected={lastUsedTemplateSelected}
            setLastUsedTemplateSelected={setLastUsedTemplateSelected}
            previewTemplate={previewTemplate}
            removeFromBulkBrands={removeFromBulkBrands}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={close} size="small" color="secondary">
          Cancel
        </Button>

        {sendOption &&
          !editList &&
          !(
            preview?.outreachMessages && preview?.outreachMessages?.length > 0
          ) && (
            <LoadingButton
              disableElevation
              size="small"
              variant="contained"
              loading={loading}
              onClick={startSend}
            >
              Start Send{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-regular fa-paper-plane"
              />
            </LoadingButton>
          )}
      </DialogActions>
    </Dialog>
  );
}
