import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface AdminEmailVerificationProps {
  setAlert: SetAlertType;
}

const AdminLastIdMigration = ({ setAlert }: AdminEmailVerificationProps) => {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState<string>("migration");
  const [funcName, setFuncName] = useState<string>("");
  const [limit, setLimit] = useState<number>(1);
  const [lastId, setLastId] = useState<number>(0);

  const handleRunMigration = async () => {
    if (funcName === "" || queue === "") {
      setAlert("Must enter a func_name and queue", "error");
      return;
    }

    const body = {
      payload: {
        func_name: funcName,
        limit: limit,
        last_id: lastId,
        queue: queue,
      },
      queue: queue,
    };

    try {
      setLoading(true);
      await fetcherAuth(
        getToken,
        `/api/admin/migration/start`,
        "POST",
        {},
        body,
      );
      setAlert("Successfully started migration", "success");
    } catch (error) {
      setAlert(error?.message || "Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      <Typography variant="h4" gutterBottom>
        <strong>Last ID Migration</strong>
      </Typography>

      <Grid item sx={{ mb: 2 }}>
        <Typography component="div">Run a last_id migration.</Typography>

        <Grid container sx={{ my: 1 }} gap={2}>
          <TextField
            onChange={(e: CustomEvent) => setQueue(e.target.value)}
            name="queue"
            value={queue}
            label="queue"
          />
          <TextField
            onChange={(e: CustomEvent) => setFuncName(e.target.value)}
            name="func_name"
            value={funcName}
            label="func_name"
          />
          <TextField
            onChange={(e: CustomEvent) => setLimit(e.target.value)}
            name="limit"
            type="number"
            value={limit}
            label="limit"
          />
          <TextField
            onChange={(e: CustomEvent) => setLastId(e.target.value)}
            name="last_id"
            type="number"
            value={lastId}
            label="last_id"
          />
        </Grid>
      </Grid>

      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleRunMigration}
      >
        Run Migration
      </LoadingButton>
    </Grid>
  );
};

export default AdminLastIdMigration;
