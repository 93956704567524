import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { AVOID_NICHES, NICHE_MAP } from "utils/localStorage";
import { makeDeepCopy } from "utils/updateLocalState";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import styles from "./styles";

type Niche = {
  name: string;
  brands: string;
  // Only use on the FE
  placeholder: string;
};

const MAXIMUM_NICHES_ALLOWED = 4;

const VANiche = () => {
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOrg } = useContext(OrganizationUserContext);
  const slideContext = useContext(SlideAnimationContext);

  const [niches, setNiches] = useState<Niche[]>([]);
  const [avoidNiches, setAvoidNiches] = useState<string>("");
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding/va-niche`,
        "POST",
        {},
        {
          niches,
          avoidNiches,
        },
      );
      if (slideContext) {
        await slideContext.slideOutUp();
        slideContext.slideInUp();
      }
      navigate(
        `/${vaOnboardingNavigate(location.pathname, "next")}${location.search}`,
      );
      storeToLocalStorage();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e: CustomEvent, index: number) => {
    const { name, value } = e.target;
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = value;
      return copy;
    });
  };

  const storeToLocalStorage = () => {
    localStorage.setItem(
      `${currentOrg?.id}-${NICHE_MAP}`,
      JSON.stringify(niches),
    );
    localStorage.setItem(`${currentOrg?.id}-${AVOID_NICHES}`, avoidNiches);
  };

  const handleAddMoreNiche = () => {
    setNiches((prev) => [
      ...prev,
      { placeholder: "Enter another niche here...", brands: "", name: "" },
    ]);
  };

  const handleDeleteNiche = (e: CustomEvent, index: number) => {
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      if (copy?.length === 1) {
        setAlert("Please fill out at least one niche", "error");
        return copy;
      }
      copy.splice(index, 1);
      localStorage.setItem(
        `${currentOrg?.id}-${NICHE_MAP}`,
        JSON.stringify(copy),
      );
      return copy;
    });
  };

  const renderNicheQuestion = (placeholder: string, index: number) => {
    if (niches?.length < index) {
      return <></>;
    }

    return (
      <Grid container alignItems="flex-start">
        <Grid container alignItems="center" item xs={12} md={1.5}>
          <IconButton
            onClick={(e) => handleDeleteNiche(e, index)}
            sx={styles.delete}
          >
            <Box component="i" className="fa-regular fa-trash" />
          </IconButton>
          <Typography sx={styles.nicheText}>Niche {index + 1}</Typography>
        </Grid>

        <Grid item xs md>
          <Grid item xs={12}>
            <TextField
              sx={styles.textField}
              fullWidth
              name="name"
              placeholder={placeholder}
              onChange={(e) => onChange(e, index)}
              value={niches[index]["name"]}
              onBlur={storeToLocalStorage}
              required
            />
            <TextField
              sx={styles.textField}
              fullWidth
              name="brands"
              placeholder="List the biggest brand names you've worked with in this niche"
              onChange={(e) => onChange(e, index)}
              value={niches[index]["brands"]}
              onBlur={storeToLocalStorage}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getTotalNiches = () => {
    const totalNichesFilled = niches?.filter(
      (x) => x.name && x.name?.trim()?.length > 0,
    );
    return totalNichesFilled?.length;
  };

  useEffect(() => {
    if (currentOrg?.id) {
      const prefilledNiche = localStorage.getItem(
        `${currentOrg?.id}-${NICHE_MAP}`,
      );
      if (prefilledNiche && prefilledNiche !== "undefined") {
        setNiches(JSON.parse(prefilledNiche));
      } else {
        setNiches([
          {
            placeholder:
              "Which niche have you earned the most income from through paid deals?",
            brands: "",
            name: "",
          },
          {
            placeholder:
              "A brand category where you'd like to secure more partnerships, or one where'd you have past success",
            brands: "",
            name: "",
          },
        ]);
      }

      const prefilledAvoidNiches = localStorage.getItem(
        `${currentOrg?.id}-${AVOID_NICHES}`,
      );
      if (prefilledAvoidNiches && prefilledAvoidNiches !== "undefined") {
        setAvoidNiches(prefilledAvoidNiches);
      }
    }
  }, [currentOrg?.id]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Pre-Onboarding Call Questions
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Please select up to <strong>4 brand niches*</strong> that you'd like
        Bento's Virtual Assistant to prioritize for pitching.
      </Typography>

      <form onSubmit={handleNext}>
        {niches?.map((niche, index) =>
          renderNicheQuestion(niche.placeholder, index),
        )}
        {niches?.length < MAXIMUM_NICHES_ALLOWED && (
          <Button sx={styles.button} onClick={handleAddMoreNiche}>
            + Add Niche
          </Button>
        )}

        <Divider sx={{ my: 4 }} />

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          If there are any niches you <strong>do not want</strong> us to pitch,
          please list them down here.
        </Typography>

        <Grid container alignItems="flex-start" sx={{ mt: 2 }}>
          <Grid container item xs={12} md={1.5}>
            <Typography sx={styles.nicheText}>Niches to Avoid</Typography>
          </Grid>

          <Grid item xs md>
            <TextField
              fullWidth
              sx={styles.textField}
              placeholder="Are there any brand types that you would like to avoid the VA reaching out to? (e.g pets, maternity, gaming apps)"
              onChange={(e) => setAvoidNiches(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormHelperText sx={{ mt: 6 }}>
            *A content creator's niche (or category) is a specific type of
            content they focus on, often centered around a particular industry,
            audience or theme, such as <i>beauty, fitness, or tech</i> that
            aligns with the brands they work with.
          </FormHelperText>
        </Grid>

        <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <LoadingButton
              disableElevation
              disabled={getTotalNiches() === 0}
              variant="contained"
              loading={loading}
              type="submit"
            >
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VANiche;
