import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { Dispatch, SetStateAction, useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { copyToClipboard } from "utils/general";
import { trackEvent } from "utils/tracking";

import { BentoBrandLinks } from "./helpers";
import { EditField } from "./schema";
import styles from "./styles";

interface Props {
  name: string;
  bentoBrand: BentoBrand;
  setEditField: Dispatch<SetStateAction<EditField>>;
}
const BrandLink = ({ name, bentoBrand, setEditField }: Props) => {
  const { setAlert } = useContext(AlertContext);
  const fieldValue = bentoBrand[name as keyof BentoBrand];

  const link = `${BentoBrandLinks[name]?.prefix}${fieldValue}`;

  const icon = BentoBrandLinks[name]?.icon;
  const tooltip = BentoBrandLinks[name]?.tooltip;

  const clickLink = () => {
    window.open(link, "_blank");
    trackEvent("Link Clicked On", { link, "Clicked From": "Brand Details" });
  };

  const isLongLink = name?.includes("Url");

  if (!fieldValue) return null;

  const handleEditLink = (e: CustomEvent) => {
    e.stopPropagation();
    trackEvent("Edit Link Clicked", { link });
    setEditField({
      name: name,
      value: link,
      label: BentoBrandLinks[name]?.tooltip,
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      key={name}
    >
      <Grid container item xs alignItems="center">
        <Typography component="div" noWrap sx={styles.link} onClick={clickLink}>
          <Box component="i" className={icon} sx={{ mr: 1 }} />{" "}
          {isLongLink ? `View link` : fieldValue?.toString()}{" "}
        </Typography>
        <Tooltip title="Edit this link">
          <IconButton sx={{ fontSize: 12 }} onClick={handleEditLink}>
            <Box component="i" className="fa-regular fa-edit" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={1}>
        <Tooltip title={`Copy ${tooltip}`}>
          <IconButton
            onClick={() => copyToClipboard(link, setAlert)}
            sx={styles.button}
          >
            <Box component="i" className="fa-regular fa-clipboard" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default BrandLink;
