import { Divider, Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { OrganizationUser } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import Settings from "../Settings";
import AdminSearchThreads from "../Threads";

interface AdminOrganizationViewProps {
  setAlert: SetAlertType;
  organizationUser: OrganizationUser | null;
  setOrganizationUser: Dispatch<SetStateAction<OrganizationUser | null>>;
}

export default function AdminOrganizationView({
  setAlert,
  organizationUser,
  setOrganizationUser,
}: AdminOrganizationViewProps) {
  const { id } = useParams();

  return id ? (
    <Grid>
      <Settings
        organizationId={Number(id)}
        setAlert={setAlert}
        organizationUser={organizationUser}
        setOrganizationUser={setOrganizationUser}
      />
      <Divider sx={{ my: 3 }} />
      <Typography variant="h4" sx={{ my: 2 }}>
        <strong>View Emails</strong>
      </Typography>
      <AdminSearchThreads organizationId={Number(id)} setAlert={setAlert} />
    </Grid>
  ) : (
    <></>
  );
}
