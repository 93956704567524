import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";

export function vaOnboardingNavigate(
  currentPathname: string,
  direction: "next" | "back",
  paidThroughSettingsPage = false,
) {
  if (currentPathname.endsWith(routes.aboutVaOnboarding)) {
    if (direction === "next") {
      return routes.categoryVaOnboarding;
    } else {
      // no back button for about
      return routes.aboutVaOnboarding;
    }
  } else if (currentPathname.endsWith(routes.categoryVaOnboarding)) {
    if (direction === "next") {
      return routes.recommendationVaOnboarding;
    } else {
      return routes.aboutVaOnboarding;
    }
  } else if (currentPathname.endsWith(routes.recommendationVaOnboarding)) {
    if (direction === "next") {
      return routes.incomeOnboarding;
    } else {
      return routes.categoryVaOnboarding;
    }
  } else if (currentPathname.endsWith(routes.incomeOnboarding)) {
    if (direction === "next") {
      return routes.vaIntroduction;
    } else {
      return routes.recommendationVaOnboarding;
    }
  } else if (currentPathname.endsWith(routes.vaIntroduction)) {
    if (direction === "next") {
      if (paidThroughSettingsPage) {
        return routes.vaStepsToGetStarted;
      } else {
        return routes.vaPaidPortal;
      }
    } else {
      return routes.incomeOnboarding;
    }
  } else if (currentPathname.endsWith(routes.vaPaidPortal)) {
    if (direction === "next") {
      return routes.vaStepsToGetStarted;
    } else {
      return routes.vaIntroduction;
    }
  } else if (currentPathname.endsWith(routes.vaStepsToGetStarted)) {
    if (direction === "next") {
      return routes.vaVideo;
    } else {
      if (paidThroughSettingsPage) {
        return routes.vaIntroduction;
      } else {
        return routes.vaPaidPortal;
      }
    }
  } else if (currentPathname.endsWith(routes.vaVideo)) {
    if (direction === "next") {
      return routes.vaNiche;
    } else {
      return routes.vaStepsToGetStarted;
    }
  } else if (currentPathname.endsWith(routes.vaNiche)) {
    if (direction === "next") {
      return routes.vaFaq;
    } else {
      return routes.vaVideo;
    }
  } else if (currentPathname.endsWith(routes.vaFaq)) {
    if (direction === "next") {
      return routes.dashboard;
    } else {
      return routes.vaNiche;
    }
  }
}

export function onboardingNavigate(
  currentPathname: string,
  onboardingForm: Map,
  direction: "next" | "back",
  transferredFromVa = false,
) {
  if (currentPathname.endsWith(routes.aboutOnboarding)) {
    if (direction === "next") {
      return routes.categoryOnboarding;
    } else {
      // no back button for about
      return routes.aboutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.categoryOnboarding)) {
    if (direction === "next") {
      return routes.pitchOnboarding;
    } else {
      return routes.aboutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchOnboarding)) {
    if (direction === "next") {
      if (!onboardingForm.hasPitchEmail) {
        return routes.pitchingGoalOnboarding;
      } else {
        return routes.currentPitchOnboarding;
      }
    } else {
      return routes.categoryOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchingGoalOnboarding)) {
    if (direction === "next") {
      return routes.pitchingHighlightOnboarding;
    } else {
      return routes.pitchOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchingHighlightOnboarding)) {
    if (direction === "next") {
      if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      return routes.pitchingGoalOnboarding;
    }
  } else if (currentPathname.endsWith(routes.portfolioOnboarding)) {
    if (direction === "next") {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.partnershipsOnboarding)) {
    if (direction === "next") {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.engagementOnboarding)) {
    if (direction === "next") {
      return routes.standOutOnboarding;
    } else {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.standOutOnboarding)) {
    if (direction === "next") {
      return routes.draftTemplateOnboarding;
    } else {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.draftTemplateOnboarding)) {
    if (direction === "next") {
      return transferredFromVa
        ? routes.selectBrandOnboarding
        : routes.recommendationOnboarding;
    } else {
      return routes.standOutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.recommendationOnboarding)) {
    if (direction === "next") {
      return routes.selectBrandOnboarding;
    }
  } else if (currentPathname.endsWith(routes.currentPitchOnboarding)) {
    if (direction === "next") {
      return transferredFromVa
        ? routes.selectBrandOnboarding
        : routes.recommendationOnboarding;
    } else {
      return routes.pitchOnboarding;
    }
  } else if (currentPathname.endsWith(routes.selectBrandOnboarding)) {
    if (direction === "back") {
      return routes.recommendationOnboarding;
    }
  }
}
