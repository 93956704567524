import { useAuth } from "@clerk/clerk-react";
import { debounce } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { BentoBrand, SavedBrand, SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";
import { getNow } from "utils/time";
import { useCollection } from "utils/useCollection";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { PaginationType } from "./QuickSendPagination";
import { SavedBrandCollectionsContext } from "./SavedBrandCollections";

interface SavedBrandContextInterface {
  handleAdd: (brand: BentoBrand) => void;
  handleAddDebounced: (e: CustomEvent, brand: BentoBrand) => void;
  handleRemoveDebounced: (brand: BentoBrand, collectionId: number) => void;
  handleMoveDebounced: (
    brand: BentoBrand,
    oldCollectionId: number,
    newCollectionId: number,
  ) => void;
  removeLoading: number;
  getId: (brand: PaginationType | undefined) => number;
  brandBeingSaved: SavedBrand | null;
  bulkBrands: BentoBrand[];
  setBulkBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  bulkMoveTo: (newCollectionId: number) => void;
  bulkDelete: () => void;
  moveLoading: boolean;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  anchorEl: any;
  setAnchorEl: any;
  removeBrand: (bentoBrand: BentoBrand) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleAdd: defaultContextMissingFunction,
  handleAddDebounced: defaultContextMissingFunction,
  handleRemoveDebounced: defaultContextMissingFunction,
  handleMoveDebounced: defaultContextMissingFunction,
  removeLoading: -1,
  moveLoading: false,
  getId: defaultContextMissingFunction,
  brandBeingSaved: null,
  bulkBrands: [],
  setBulkBrands: defaultContextMissingFunction,
  bulkMoveTo: defaultContextMissingFunction,
  bulkDelete: defaultContextMissingFunction,
  selectAll: false,
  setSelectAll: defaultContextMissingFunction,
  anchorEl: null,
  setAnchorEl: defaultContextMissingFunction,
  removeBrand: defaultContextMissingFunction,
};

const SavedBrandContext =
  createContext<SavedBrandContextInterface>(defaultInterface);

interface SavedBrandProviderProps {
  children: React.ReactNode;
}

const SavedBrandProvider = ({ children }: SavedBrandProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { currentTab, getCollectionBrands } = useContext(
    SavedBrandCollectionsContext,
  );
  const { modifySavedBrands, addToCollectionTab } = useCollection();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [brandBeingSaved, setBrandBeingSaved] = useState<SavedBrand | null>(
    null,
  );
  const [bulkBrands, setBulkBrands] = useState<BentoBrand[]>([]);
  const [saveLoading, setSaveLoading] = useState(-1);
  const [removeLoading, setRemoveLoading] = useState(-1);
  const [moveLoading, setMoveLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const savedBrands = getCollectionBrands(0, currentTab) ?? [];

  const getId = (brand: PaginationType | undefined) => {
    if (!brand) return -1;
    return "bentoBrandId" in brand
      ? Number(brand.bentoBrandId)
      : Number(brand.id);
  };

  const handleAdd = (brand: BentoBrand, newCollectionId: number = 0) => {
    const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));
    const savedBrand = {
      bentoBrand: {
        ...brand,
        savedBrandCollectionId: newCollectionId,
        isAdmin,
      },
      bentoBrandId: brand.id,
      createdAt: getNow(),
    };
    modifySavedBrands(savedBrand, "add", newCollectionId);
    setBrandBeingSaved(savedBrand);
  };

  const handleRemove = (brand: BentoBrand) => {
    modifySavedBrands(
      {
        bentoBrand: brand,
        bentoBrandId: brand.id,
        createdAt: getNow(),
      },
      "remove",
    );
    setBrandBeingSaved(null);
  };

  const handleMove = (brand: BentoBrand, newCollectionId: number) => {
    handleAdd(brand, newCollectionId);
    setBrandBeingSaved(null);
  };

  const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));
  const handleAddDebounced = useMemo(
    () =>
      debounce(async (eventTarget: any, brand: BentoBrand) => {
        setAnchorEl(eventTarget);
        handleAdd(brand);
        try {
          const res = await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}`,
            "PUT",
            {},
            {
              isAdmin,
            },
          );
          const updatedBentoBrand: BentoBrand = res.savedBrand.bentoBrand;
          if (updatedBentoBrand?.orgHasOutreachContact) {
            addToCollectionTab(
              res.savedBrand,
              res.savedBrand.savedBrandCollectionId || 0,
              SavedBrandStatus.sent,
            );
          }
        } catch (error) {
          setErrorAlert(error);
        } finally {
          setSaveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, saveLoading],
  );

  const handleRemoveDebounced = useMemo(
    () =>
      debounce(async (brand: BentoBrand, collectionId: number) => {
        if (!currentOrg?.id) return;
        handleRemove(brand);
        removeBrand(brand);
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, removeLoading, savedBrands?.length],
  );

  const handleMoveDebounced = useMemo(
    () =>
      debounce(
        async (
          brand: BentoBrand,
          oldCollectionId: number,
          newCollectionId: number,
        ) => {
          if (!currentOrg?.id) return;
          handleMove(brand, newCollectionId);
          moveBrand(brand, newCollectionId);
        },
        50,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, removeLoading, savedBrands?.length],
  );

  const removeBrand = async (brand: BentoBrand) => {
    try {
      setRemoveLoading(brand?.id);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}`,
        "DELETE",
      );
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setRemoveLoading(-1);
    }
  };

  const moveBrand = async (brand: BentoBrand, newCollectionId: number) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}`,
        "PATCH",
        {},
        {
          savedBrandCollectionId: newCollectionId,
          isAdmin,
        },
      );
      const updatedBentoBrand: BentoBrand = res.savedBrand.bentoBrand;
      if (updatedBentoBrand?.orgHasOutreachContact) {
        addToCollectionTab(
          res.savedBrand,
          newCollectionId,
          SavedBrandStatus.sent,
        );
      }
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const bulkMoveTo = async (newCollectionId: number) => {
    setMoveLoading(true);
    await Promise.all(
      bulkBrands?.map((brand) => {
        handleMove(brand, newCollectionId);
        return moveBrand(brand, newCollectionId);
      }),
    );
    setMoveLoading(false);
    setAlert("Successfully moved brands", "success");
  };

  const bulkDelete = async () => {
    await Promise.all(
      bulkBrands?.map((brand) => {
        handleRemove(brand);
        return removeBrand(brand);
      }),
    );
  };

  return (
    <SavedBrandContext.Provider
      value={{
        getId,
        handleAdd,
        handleAddDebounced,
        handleRemoveDebounced,
        handleMoveDebounced,
        removeLoading,
        brandBeingSaved,
        bulkBrands,
        setBulkBrands,
        bulkMoveTo,
        bulkDelete,
        moveLoading,
        selectAll,
        setSelectAll,
        anchorEl,
        setAnchorEl,
        removeBrand,
      }}
    >
      {children}
    </SavedBrandContext.Provider>
  );
};

export { SavedBrandProvider, SavedBrandContext };
