import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { SetAlertType } from "schemas/functions";
import { Plans } from "schemas/payments";
import { routes } from "schemas/routes";

import { useSubscription } from "utils/useSubscription";

import styles from "./styles";

interface ViewScoreDialogProps {
  open: boolean;
  handleClose: () => void;
  setAlert: SetAlertType;
}

export default function ViewScoreDialog({
  open,
  handleClose,
  setAlert,
}: ViewScoreDialogProps) {
  const { plans } = useContext(SubscriptionContext);
  const { checkoutLoading, handleCheckout } = useSubscription();

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton sx={styles.closeButton} onClick={handleClose}>
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center" }}>
        This is a feature for Pro Plan Users 🔐
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        Unlock exclusive access to brands more likely to respond with Bento's
        "Reply Score" feature. Bento assigns a rating to brands and contact
        persons based on whether they have previously responded to a creator on
        the platform.
        {plans && (
          <Typography sx={{ mt: 2 }} variant="h6">
            <strong>${plans[Plans.PRO]?.price}/month</strong>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <LoadingButton
            disableElevation
            variant="contained"
            loading={checkoutLoading}
            onClick={() =>
              handleCheckout(
                Plans.PRO,
                undefined,
                "_self",
                `/${routes.dashboard}`,
                `View Score Dialog`,
              )
            }
          >
            Subscribe
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
