import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import moment from "moment";
import { forwardRef, useContext, useState } from "react";
import { BentoBrand, LOCATIONS, SearchParams } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import EmailButton from "components/EmailButton";
import { DiscoverSearchChipColor } from "utils/color";
import { getSourceLabel } from "utils/influencer";
import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
  collectionId?: number;
  isBulkMode?: boolean;
  fromDeleteDraft?: boolean;
  deleteDraft?: () => void;
  isDefaultCollection?: boolean;
}

const SelectedBrand = forwardRef(
  (
    {
      bentoBrand,
      collectionId = -1,
      isBulkMode,
      fromDeleteDraft = false,
      isDefaultCollection = false,
    }: SelectedBrandProps,
    ref,
  ) => {
    const theme = useTheme();
    const { isDraftBrand } = useCollection();
    const { removeDraftFromList, removeDraftApiCall } = useDrafts();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // Contexts
    const {
      selectAll,
      setSelectAll,
      handleRemoveDebounced,
      removeLoading,
      bulkBrands,
      setBulkBrands,
    } = useContext(SavedBrandContext);
    const { handleOpenQuickSendIndividual, quickSendBrandId } = useContext(
      QuickSendDrawerContext,
    );
    const { getCollection } = useContext(SavedBrandCollectionsContext);
    const { frozenIds } = useContext(AutogeneratedDraftsContext);

    const isSelected = quickSendBrandId === bentoBrand?.id;
    const isFrozen = frozenIds?.includes(Number(bentoBrand?.id));
    const isChecked = Boolean(
      bulkBrands?.find((x) => Number(x.id) === Number(bentoBrand.id)),
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleDelete = async (e: CustomEvent) => {
      e.stopPropagation();

      removeDraftFromList(bentoBrand, collectionId);

      // When user remove drafts from the Popup Dialog
      if (fromDeleteDraft) {
        setBulkBrands((prev) => {
          const copy = makeDeepCopy(prev);
          const index = prev.findIndex(
            (b) => Number(b.id) === Number(bentoBrand.id),
          );
          if (index > -1) {
            copy[index]["hasOutreachDraft"] = false;
          }
          return copy;
        });
        await removeDraftApiCall(
          e,
          bentoBrand,
          "Write Me Drafts Dialog",
          false,
        );
      } else {
        handleRemoveDebounced(bentoBrand, collectionId);
      }
    };

    const handleSelect = () => {
      if (selectAll) {
        setSelectAll(false);
      }
      setBulkBrands((prev) => {
        const copy = makeDeepCopy(prev);
        const index = prev.findIndex(
          (b) => Number(b.id) === Number(bentoBrand.id),
        );
        if (index > -1) {
          copy.splice(index, 1);
        } else {
          copy.push(bentoBrand);
        }
        return copy;
      });
    };

    const clickOnBrand = () => {
      if (isFrozen) return;
      handleOpenQuickSendIndividual(
        bentoBrand?.id,
        "Favorites List",
        undefined,
        true,
      );
    };

    const goToInstagram = (e: CustomEvent) => {
      e.stopPropagation();
      trackEvent("Instagram Link Viewed", {
        "Instagram Username": bentoBrand.instagramUsername,
      });
      window.open(
        `https://instagram.com/${bentoBrand.instagramUsername}`,
        "_blank",
      );
    };

    const renderLeftButton = () => {
      if (isFrozen) return <></>;
      if (isBulkMode) {
        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <Checkbox
              onChange={handleSelect}
              disableRipple
              checked={isChecked}
              icon={
                <Box
                  sx={{ color: "green.main" }}
                  component="i"
                  className="fa-regular fa-square"
                />
              }
              checkedIcon={
                <Box
                  component="i"
                  className="fa-solid fa-square-check"
                  sx={{ color: "green.main" }}
                />
              }
            />
          </Box>
        );
      } else {
        return (
          <>
            {removeLoading === bentoBrand?.id ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleDelete} sx={styles.removeButton}>
                <Box component="i" className="fa-regular fa-circle-xmark" />
              </IconButton>
            )}
          </>
        );
      }
    };

    const renderDateOnEmail = () => {
      if (bentoBrand?.earliestPendingSendAt) {
        return (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Scheduled for{" "}
            <strong>
              <em>
                {" "}
                {moment(bentoBrand?.earliestPendingSendAt).format(
                  "MMM DD, YYYY",
                )}
              </em>
            </strong>
          </Typography>
        );
      } else if (bentoBrand?.latestEmailCompletedAt) {
        return (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Emailed on{" "}
            <strong>
              <em>
                {" "}
                {moment(bentoBrand?.latestEmailCompletedAt).format(
                  "MMM DD, YYYY",
                )}
              </em>
            </strong>
          </Typography>
        );
      } else {
        return <></>;
      }
    };

    const matchedLocation = LOCATIONS?.find(
      (x) => x.key === bentoBrand?.countryCode?.toLowerCase(),
    );
    const location = matchedLocation
      ? matchedLocation?.name
      : bentoBrand?.countryCode;

    const punctuationMatch = bentoBrand?.aboutSummary?.match(/[^.!?]+[.!?]+/g);
    const firstSentence = punctuationMatch?.[0];

    return (
      <Box
        ref={ref}
        sx={[styles.brandContainer, isSelected && styles.selected]}
        onClick={clickOnBrand}
        key={`cart-brand-${bentoBrand.id}`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {renderLeftButton()}
        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>
            {bentoBrand.brandName}
            {!isFrozen &&
              (isDraftBrand(bentoBrand) || bentoBrand?.hasOutreachDraft) && (
                <>
                  ,{" "}
                  <Box component="span" sx={styles.draft}>
                    Draft{" "}
                  </Box>
                </>
              )}
            {isFrozen && <Box sx={styles.frozen}>Generating...</Box>}
          </Box>

          {bentoBrand.instagramUsername && (
            <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          {bentoBrand?.savedBrandCollectionId && collectionId === 0 ? (
            <Typography variant="body2" sx={{ mt: 1 }}>
              In{" "}
              <strong>
                <em>
                  {getCollection(bentoBrand?.savedBrandCollectionId)?.name}
                </em>
              </strong>
            </Typography>
          ) : (
            <></>
          )}

          {renderDateOnEmail()}

          {isDefaultCollection && (
            <FormHelperText
              sx={{
                backgroundColor: "white",
                fontSize: 10,
                color: "secondary.dark",
              }}
            >
              {getSourceLabel(bentoBrand)}
            </FormHelperText>
          )}
        </Box>

        {!fromDeleteDraft && <EmailButton isFrozen={isFrozen} />}

        {!isMobileScreen && (
          <Popover
            id={`brand-popover-${bentoBrand.id}`}
            sx={{ pointerEvents: "none" }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ p: 1, maxWidth: 300 }}>
              <Grid container item xs={12} gap={1}>
                {location && (
                  <Chip
                    sx={{
                      backgroundColor:
                        DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER],
                      fontSize: 10,
                    }}
                    size="small"
                    label={location}
                  />
                )}
                {bentoBrand?.categories?.slice(0, 2).map((category, index) => (
                  <Chip
                    sx={{
                      backgroundColor:
                        DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
                      fontSize: 10,
                    }}
                    size="small"
                    label={category}
                    key={index}
                  />
                ))}
              </Grid>
              <Typography sx={{ fontSize: 10 }}>{firstSentence}</Typography>
            </Box>
          </Popover>
        )}
      </Box>
    );
  },
);

export default SelectedBrand;
