import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { Dispatch, SetStateAction, useContext } from "react";

import { useSearchBrand } from "utils/useSearchBrand";

import DiscoverChips from "./Chips";
import styles from "./styles";

interface Props {
  hasTags: boolean;
  openCategoryFilter: () => void;
  openFilter: boolean;
  setOpenFilter: Dispatch<SetStateAction<boolean>>;
  totalDisplay: number;
  buttons: JSX.Element;
  filtersList: JSX.Element;
  sortFilter: JSX.Element;
}

const DiscoverFilterMobile = ({
  hasTags,
  openCategoryFilter,
  openFilter,
  setOpenFilter,
  totalDisplay,
  buttons,
  filtersList,
  sortFilter,
}: Props) => {
  const { selectedTags } = useSearchBrand();
  const { selectedMetadataTags } = useContext(BrandsContext);
  return (
    <>
      <Box sx={styles.root}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            {hasTags ? (
              <Button
                disableElevation
                onClick={() => setOpenFilter(true)}
                variant="contained"
                size={"small"}
                color="secondary"
                sx={{ fontSize: { xs: 11, md: 14 } }}
              >
                Filters ({totalDisplay})
              </Button>
            ) : (
              <Button
                onClick={() => setOpenFilter(true)}
                size={"small"}
                sx={{ fontSize: { xs: 11, md: 14 } }}
              >
                Filters{" "}
                <Box
                  component="i"
                  className="fa-solid fa-filter"
                  sx={{ ml: 1 }}
                />
              </Button>
            )}
          </Grid>

          <Grid item container xs justifyContent="flex-end">
            {buttons}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openFilter}
        fullScreen={true}
        onClose={() => setOpenFilter(false)}
      >
        <DialogTitle sx={{ backgroundColor: "secondary.light", py: 1 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            FILTERS
            <IconButton
              onClick={() => setOpenFilter(false)}
              sx={{ width: 20, height: 20, fontSize: 14 }}
            >
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ px: 0 }}>
          <Grid
            onClick={openCategoryFilter}
            container
            justifyContent="space-between"
            alignItems="center"
            sx={styles.mobileList}
          >
            <Box sx={{ fontSize: 16 }}>Categories</Box>{" "}
            <Box component="i" className="fa-regular fa-arrow-right" />
          </Grid>

          {filtersList}
          {sortFilter}
          <DiscoverChips
            selectedTags={selectedTags}
            selectedMetadataTags={selectedMetadataTags}
          />
        </DialogContent>

        <DialogActions sx={{ py: 2 }}>
          <Button
            variant="contained"
            size="small"
            disableElevation
            onClick={() => setOpenFilter(false)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DiscoverFilterMobile;
