import { useAuth } from "@clerk/clerk-react";
import { Link } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "./api";

export const useSubscription = () => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription } = useContext(SubscriptionContext);
  const { getToken } = useAuth();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const cannotUpgrade =
    (subscription?.isSubscribed &&
      subscription?.subscription?.planName === Plans.VA_YEARLY) ||
    currentOrg?.overridePlanName;

  const handleCheckout = async (
    plan: Plans,
    flow: string | undefined = undefined,
    openTarget = "_self",
    pageSource?: string,
    source?: string,
  ) => {
    setCheckoutLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/checkout`,
        "POST",
        {},
        {
          plan,
          flow,
          pageSource,
          source,
        },
      );
      window.open(res.session.url, openTarget);
    } catch (error) {
      setAlert(
        <>
          Unable to create checkout session. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists
        </>,
        "error",
      );
    } finally {
      setCheckoutLoading(false);
    }
  };

  return { handleCheckout, checkoutLoading, cannotUpgrade };
};
